/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkExecutionStatsDto,
  BulkExecutionStatusDto,
  CancelExecutionDto,
  CancellationResultDto,
  DunningPageRequest,
  ErrorResponse,
  ExecutionFilterDto,
  PaginatedExecutionOverviewDto,
  RuleSetExecutionsSummaryDto,
  SkippedExecutionContractsDto,
} from '../models/index';
import {
    BulkExecutionStatsDtoFromJSON,
    BulkExecutionStatsDtoToJSON,
    BulkExecutionStatusDtoFromJSON,
    BulkExecutionStatusDtoToJSON,
    CancelExecutionDtoFromJSON,
    CancelExecutionDtoToJSON,
    CancellationResultDtoFromJSON,
    CancellationResultDtoToJSON,
    DunningPageRequestFromJSON,
    DunningPageRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExecutionFilterDtoFromJSON,
    ExecutionFilterDtoToJSON,
    PaginatedExecutionOverviewDtoFromJSON,
    PaginatedExecutionOverviewDtoToJSON,
    RuleSetExecutionsSummaryDtoFromJSON,
    RuleSetExecutionsSummaryDtoToJSON,
    SkippedExecutionContractsDtoFromJSON,
    SkippedExecutionContractsDtoToJSON,
} from '../models/index';

export interface BulkExecutionRequest {
    executionFilterDto: ExecutionFilterDto;
}

export interface CancelExecutionRequest {
    cancelExecutionDto: CancelExecutionDto;
}

export interface DownloadExecutionsRequest {
    contractId?: number;
    customerId?: number;
    executedAfter?: string;
    executedBefore?: string;
    states?: string;
    outcomeStates?: Array<DownloadExecutionsOutcomeStatesEnum>;
    outcomeIds?: Array<string>;
    actionTypes?: string;
    dunningLevels?: Array<string>;
    ruleSetNames?: Array<string>;
}

export interface GetExecutionsRequest {
    filterDto: ExecutionFilterDto;
    dunningPageRequest: DunningPageRequest;
}

export interface GetRuleSetSummaryRequest {
    ruleSetId: string;
}

/**
 * 
 */
export class ExecutionsApi extends runtime.BaseAPI {

    /**
     * Mass launch a retry of the execution outcomes
     * Retry
     */
    async bulkExecutionRaw(requestParameters: BulkExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkExecutionStatsDto>> {
        if (requestParameters['executionFilterDto'] == null) {
            throw new runtime.RequiredError(
                'executionFilterDto',
                'Required parameter "executionFilterDto" was null or undefined when calling bulkExecution().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/executions/retry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecutionFilterDtoToJSON(requestParameters['executionFilterDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkExecutionStatsDtoFromJSON(jsonValue));
    }

    /**
     * Mass launch a retry of the execution outcomes
     * Retry
     */
    async bulkExecution(requestParameters: BulkExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkExecutionStatsDto> {
        const response = await this.bulkExecutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the state of the given exections to \"canceled\" and revert the dunning levels of the receivables
     * Cancel given execution\'s state
     */
    async cancelExecutionRaw(requestParameters: CancelExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CancellationResultDto>> {
        if (requestParameters['cancelExecutionDto'] == null) {
            throw new runtime.RequiredError(
                'cancelExecutionDto',
                'Required parameter "cancelExecutionDto" was null or undefined when calling cancelExecution().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/executions/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelExecutionDtoToJSON(requestParameters['cancelExecutionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CancellationResultDtoFromJSON(jsonValue));
    }

    /**
     * Set the state of the given exections to \"canceled\" and revert the dunning levels of the receivables
     * Cancel given execution\'s state
     */
    async cancelExecution(requestParameters: CancelExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CancellationResultDto> {
        const response = await this.cancelExecutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download a csv file of the executions which match the filter conditions CSV file columns: `Customer Id`, `Contract Id`, `Ruleset`, `Actions`, `Retried Date`, `Retried by`. 
     * Download CSV file
     */
    async downloadExecutionsRaw(requestParameters: DownloadExecutionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters['contractId'] != null) {
            queryParameters['contractId'] = requestParameters['contractId'];
        }

        if (requestParameters['customerId'] != null) {
            queryParameters['customerId'] = requestParameters['customerId'];
        }

        if (requestParameters['executedAfter'] != null) {
            queryParameters['executedAfter'] = requestParameters['executedAfter'];
        }

        if (requestParameters['executedBefore'] != null) {
            queryParameters['executedBefore'] = requestParameters['executedBefore'];
        }

        if (requestParameters['states'] != null) {
            queryParameters['states'] = requestParameters['states'];
        }

        if (requestParameters['outcomeStates'] != null) {
            queryParameters['outcomeStates'] = requestParameters['outcomeStates'];
        }

        if (requestParameters['outcomeIds'] != null) {
            queryParameters['outcomeIds'] = requestParameters['outcomeIds'];
        }

        if (requestParameters['actionTypes'] != null) {
            queryParameters['actionTypes'] = requestParameters['actionTypes'];
        }

        if (requestParameters['dunningLevels'] != null) {
            queryParameters['dunningLevels'] = requestParameters['dunningLevels'];
        }

        if (requestParameters['ruleSetNames'] != null) {
            queryParameters['ruleSetNames'] = requestParameters['ruleSetNames'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/executions/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a csv file of the executions which match the filter conditions CSV file columns: `Customer Id`, `Contract Id`, `Ruleset`, `Actions`, `Retried Date`, `Retried by`. 
     * Download CSV file
     */
    async downloadExecutions(requestParameters: DownloadExecutionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadExecutionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get contracts with skip executions
     * Get skipped contracts
     */
    async getContractsStatesWithSkipExecutionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SkippedExecutionContractsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/executions/skipped-contracts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkippedExecutionContractsDtoFromJSON(jsonValue));
    }

    /**
     * Get contracts with skip executions
     * Get skipped contracts
     */
    async getContractsStatesWithSkipExecution(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SkippedExecutionContractsDto> {
        const response = await this.getContractsStatesWithSkipExecutionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all or filtered executions by pages
     * Get All by using filters
     */
    async getExecutionsRaw(requestParameters: GetExecutionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedExecutionOverviewDto>> {
        if (requestParameters['filterDto'] == null) {
            throw new runtime.RequiredError(
                'filterDto',
                'Required parameter "filterDto" was null or undefined when calling getExecutions().'
            );
        }

        if (requestParameters['dunningPageRequest'] == null) {
            throw new runtime.RequiredError(
                'dunningPageRequest',
                'Required parameter "dunningPageRequest" was null or undefined when calling getExecutions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filterDto'] != null) {
            queryParameters['filterDto'] = requestParameters['filterDto'];
        }

        if (requestParameters['dunningPageRequest'] != null) {
            queryParameters['dunningPageRequest'] = requestParameters['dunningPageRequest'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/executions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedExecutionOverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get all or filtered executions by pages
     * Get All by using filters
     */
    async getExecutions(requestParameters: GetExecutionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedExecutionOverviewDto> {
        const response = await this.getExecutionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get executions summary for given RuleSet
     * Get summary for a given RuleSet
     */
    async getRuleSetSummaryRaw(requestParameters: GetRuleSetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetExecutionsSummaryDto>> {
        if (requestParameters['ruleSetId'] == null) {
            throw new runtime.RequiredError(
                'ruleSetId',
                'Required parameter "ruleSetId" was null or undefined when calling getRuleSetSummary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/executions/ruleset/{ruleSetId}/summary`.replace(`{${"ruleSetId"}}`, encodeURIComponent(String(requestParameters['ruleSetId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetExecutionsSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Get executions summary for given RuleSet
     * Get summary for a given RuleSet
     */
    async getRuleSetSummary(requestParameters: GetRuleSetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetExecutionsSummaryDto> {
        const response = await this.getRuleSetSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get current bulk retry status
     * Retry Status
     */
    async retryStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BulkExecutionStatusDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/executions/retry/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkExecutionStatusDtoFromJSON(jsonValue));
    }

    /**
     * Get current bulk retry status
     * Retry Status
     */
    async retryStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BulkExecutionStatusDto> {
        const response = await this.retryStatusRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DownloadExecutionsOutcomeStatesEnum = {
    Pending: 'PENDING',
    AwaitingSiblings: 'AWAITING_SIBLINGS',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type DownloadExecutionsOutcomeStatesEnum = typeof DownloadExecutionsOutcomeStatesEnum[keyof typeof DownloadExecutionsOutcomeStatesEnum];
