/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchivedRuleSetDto
 */
export interface ArchivedRuleSetDto {
    /**
     * 
     * @type {string}
     * @memberof ArchivedRuleSetDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ArchivedRuleSetDto
     */
    archived?: Date;
    /**
     * 
     * @type {string}
     * @memberof ArchivedRuleSetDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchivedRuleSetDto
     */
    archivedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchivedRuleSetDto
     */
    representation?: string;
}

/**
 * Check if a given object implements the ArchivedRuleSetDto interface.
 */
export function instanceOfArchivedRuleSetDto(value: object): value is ArchivedRuleSetDto {
    return true;
}

export function ArchivedRuleSetDtoFromJSON(json: any): ArchivedRuleSetDto {
    return ArchivedRuleSetDtoFromJSONTyped(json, false);
}

export function ArchivedRuleSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchivedRuleSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'archived': json['archived'] == null ? undefined : (new Date(json['archived'])),
        'name': json['name'] == null ? undefined : json['name'],
        'archivedBy': json['archivedBy'] == null ? undefined : json['archivedBy'],
        'representation': json['representation'] == null ? undefined : json['representation'],
    };
}

export function ArchivedRuleSetDtoToJSON(value?: ArchivedRuleSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'archived': value['archived'] == null ? undefined : ((value['archived']).toISOString()),
        'name': value['name'],
        'archivedBy': value['archivedBy'],
        'representation': value['representation'],
    };
}

