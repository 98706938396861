/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExecutionOverviewDto } from './ExecutionOverviewDto';
import {
    ExecutionOverviewDtoFromJSON,
    ExecutionOverviewDtoFromJSONTyped,
    ExecutionOverviewDtoToJSON,
} from './ExecutionOverviewDto';

/**
 * 
 * @export
 * @interface PaginatedExecutionOverviewDto
 */
export interface PaginatedExecutionOverviewDto {
    /**
     * 
     * @type {Array<ExecutionOverviewDto>}
     * @memberof PaginatedExecutionOverviewDto
     */
    data?: Array<ExecutionOverviewDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedExecutionOverviewDto
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedExecutionOverviewDto
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedExecutionOverviewDto
     */
    totalAmountOfItems?: number;
}

/**
 * Check if a given object implements the PaginatedExecutionOverviewDto interface.
 */
export function instanceOfPaginatedExecutionOverviewDto(value: object): value is PaginatedExecutionOverviewDto {
    return true;
}

export function PaginatedExecutionOverviewDtoFromJSON(json: any): PaginatedExecutionOverviewDto {
    return PaginatedExecutionOverviewDtoFromJSONTyped(json, false);
}

export function PaginatedExecutionOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedExecutionOverviewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(ExecutionOverviewDtoFromJSON)),
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'totalAmountOfItems': json['totalAmountOfItems'] == null ? undefined : json['totalAmountOfItems'],
    };
}

export function PaginatedExecutionOverviewDtoToJSON(value?: PaginatedExecutionOverviewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(ExecutionOverviewDtoToJSON)),
        'pageNumber': value['pageNumber'],
        'pageSize': value['pageSize'],
        'totalAmountOfItems': value['totalAmountOfItems'],
    };
}

