/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractDunningStateDto
 */
export interface ContractDunningStateDto {
    /**
     * The id of the contract
     * @type {number}
     * @memberof ContractDunningStateDto
     */
    contractId?: number;
    /**
     * The id of the active ruleset associated with the contract
     * @type {string}
     * @memberof ContractDunningStateDto
     */
    activeRuleSetId?: string;
    /**
     * The dunning level of the contract, which is the maximum of the levels of the receivables
     * @type {string}
     * @memberof ContractDunningStateDto
     */
    dunningLevel?: string;
    /**
     * The notification level corresponding to the dunning level
     * @type {string}
     * @memberof ContractDunningStateDto
     */
    notificationLevel?: string;
    /**
     * If the execution is skipped for the contract.
     * @type {boolean}
     * @memberof ContractDunningStateDto
     */
    skipExecution?: boolean;
}

/**
 * Check if a given object implements the ContractDunningStateDto interface.
 */
export function instanceOfContractDunningStateDto(value: object): value is ContractDunningStateDto {
    return true;
}

export function ContractDunningStateDtoFromJSON(json: any): ContractDunningStateDto {
    return ContractDunningStateDtoFromJSONTyped(json, false);
}

export function ContractDunningStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractDunningStateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'contractId': json['contractId'] == null ? undefined : json['contractId'],
        'activeRuleSetId': json['activeRuleSetId'] == null ? undefined : json['activeRuleSetId'],
        'dunningLevel': json['dunningLevel'] == null ? undefined : json['dunningLevel'],
        'notificationLevel': json['notificationLevel'] == null ? undefined : json['notificationLevel'],
        'skipExecution': json['skipExecution'] == null ? undefined : json['skipExecution'],
    };
}

export function ContractDunningStateDtoToJSON(value?: ContractDunningStateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractId': value['contractId'],
        'activeRuleSetId': value['activeRuleSetId'],
        'dunningLevel': value['dunningLevel'],
        'notificationLevel': value['notificationLevel'],
        'skipExecution': value['skipExecution'],
    };
}

