/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LevelReplacementLog
 */
export interface LevelReplacementLog {
    /**
     * A dunning level present in the old ruleset, but missing in the new ruleset
     * @type {string}
     * @memberof LevelReplacementLog
     */
    oldLevelName?: string;
    /**
     * The numeric value associated with that dunning level
     * @type {number}
     * @memberof LevelReplacementLog
     */
    oldLevelValue?: number;
    /**
     * The dunning level in the new ruleset that the old level was replaced by
     * @type {string}
     * @memberof LevelReplacementLog
     */
    newLevelName?: string;
    /**
     * The numeric value associated with that dunning level
     * @type {number}
     * @memberof LevelReplacementLog
     */
    newLevelValue?: number;
    /**
     * The number of receivables that used the old level
     * @type {number}
     * @memberof LevelReplacementLog
     */
    numberOfAffectedReceivables?: number;
}

/**
 * Check if a given object implements the LevelReplacementLog interface.
 */
export function instanceOfLevelReplacementLog(value: object): value is LevelReplacementLog {
    return true;
}

export function LevelReplacementLogFromJSON(json: any): LevelReplacementLog {
    return LevelReplacementLogFromJSONTyped(json, false);
}

export function LevelReplacementLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelReplacementLog {
    if (json == null) {
        return json;
    }
    return {
        
        'oldLevelName': json['oldLevelName'] == null ? undefined : json['oldLevelName'],
        'oldLevelValue': json['oldLevelValue'] == null ? undefined : json['oldLevelValue'],
        'newLevelName': json['newLevelName'] == null ? undefined : json['newLevelName'],
        'newLevelValue': json['newLevelValue'] == null ? undefined : json['newLevelValue'],
        'numberOfAffectedReceivables': json['numberOfAffectedReceivables'] == null ? undefined : json['numberOfAffectedReceivables'],
    };
}

export function LevelReplacementLogToJSON(value?: LevelReplacementLog | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldLevelName': value['oldLevelName'],
        'oldLevelValue': value['oldLevelValue'],
        'newLevelName': value['newLevelName'],
        'newLevelValue': value['newLevelValue'],
        'numberOfAffectedReceivables': value['numberOfAffectedReceivables'],
    };
}

