/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionFilterDto
 */
export interface ExecutionFilterDto {
    /**
     * 
     * @type {number}
     * @memberof ExecutionFilterDto
     */
    contractId?: number;
    /**
     * 
     * @type {number}
     * @memberof ExecutionFilterDto
     */
    customerId?: number;
    /**
     * Date of the beginning in the format: 10-12-2022, it will consider the beginning of the day 00:00:00
     * @type {Date}
     * @memberof ExecutionFilterDto
     */
    executedAfter?: Date;
    /**
     * Date of the end in the format: 10-12-2022, it will consider the end of the day (59:59:59)
     * @type {Date}
     * @memberof ExecutionFilterDto
     */
    executedBefore?: Date;
    /**
     * The state of the execution, it can be: SUCCESSFUL, FAILED, PENDING
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    states?: Array<ExecutionFilterDtoStatesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    outcomeStates?: Array<ExecutionFilterDtoOutcomeStatesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    outcomeIds?: Array<string>;
    /**
     *   Filter the type of the execution outcomes, it can be: Notification, Fee, DisconnectionThreat,
     *   DisconnectionAnnouncement, Disconnection, ContractTermination, DebtCollection
     * 
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    actionTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    dunningLevels?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecutionFilterDto
     */
    ruleSetNames?: Array<string>;
}


/**
 * @export
 */
export const ExecutionFilterDtoStatesEnum = {
    Pending: 'PENDING',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type ExecutionFilterDtoStatesEnum = typeof ExecutionFilterDtoStatesEnum[keyof typeof ExecutionFilterDtoStatesEnum];

/**
 * @export
 */
export const ExecutionFilterDtoOutcomeStatesEnum = {
    Pending: 'PENDING',
    AwaitingSiblings: 'AWAITING_SIBLINGS',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type ExecutionFilterDtoOutcomeStatesEnum = typeof ExecutionFilterDtoOutcomeStatesEnum[keyof typeof ExecutionFilterDtoOutcomeStatesEnum];


/**
 * Check if a given object implements the ExecutionFilterDto interface.
 */
export function instanceOfExecutionFilterDto(value: object): value is ExecutionFilterDto {
    return true;
}

export function ExecutionFilterDtoFromJSON(json: any): ExecutionFilterDto {
    return ExecutionFilterDtoFromJSONTyped(json, false);
}

export function ExecutionFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionFilterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'contractId': json['contractId'] == null ? undefined : json['contractId'],
        'customerId': json['customerId'] == null ? undefined : json['customerId'],
        'executedAfter': json['executedAfter'] == null ? undefined : (new Date(json['executedAfter'])),
        'executedBefore': json['executedBefore'] == null ? undefined : (new Date(json['executedBefore'])),
        'states': json['states'] == null ? undefined : json['states'],
        'outcomeStates': json['outcomeStates'] == null ? undefined : json['outcomeStates'],
        'outcomeIds': json['outcomeIds'] == null ? undefined : json['outcomeIds'],
        'actionTypes': json['actionTypes'] == null ? undefined : json['actionTypes'],
        'dunningLevels': json['dunningLevels'] == null ? undefined : json['dunningLevels'],
        'ruleSetNames': json['ruleSetNames'] == null ? undefined : json['ruleSetNames'],
    };
}

export function ExecutionFilterDtoToJSON(value?: ExecutionFilterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractId': value['contractId'],
        'customerId': value['customerId'],
        'executedAfter': value['executedAfter'] == null ? undefined : ((value['executedAfter']).toISOString().substring(0,10)),
        'executedBefore': value['executedBefore'] == null ? undefined : ((value['executedBefore']).toISOString().substring(0,10)),
        'states': value['states'],
        'outcomeStates': value['outcomeStates'],
        'outcomeIds': value['outcomeIds'],
        'actionTypes': value['actionTypes'],
        'dunningLevels': value['dunningLevels'],
        'ruleSetNames': value['ruleSetNames'],
    };
}

