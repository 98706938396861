/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RuleDto } from './RuleDto';
import {
    RuleDtoFromJSON,
    RuleDtoFromJSONTyped,
    RuleDtoToJSON,
} from './RuleDto';
import type { RuleSetInfoDto } from './RuleSetInfoDto';
import {
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoFromJSONTyped,
    RuleSetInfoDtoToJSON,
} from './RuleSetInfoDto';
import type { RuleSetDunningLevelDto } from './RuleSetDunningLevelDto';
import {
    RuleSetDunningLevelDtoFromJSON,
    RuleSetDunningLevelDtoFromJSONTyped,
    RuleSetDunningLevelDtoToJSON,
} from './RuleSetDunningLevelDto';
import type { RuleParameterDto } from './RuleParameterDto';
import {
    RuleParameterDtoFromJSON,
    RuleParameterDtoFromJSONTyped,
    RuleParameterDtoToJSON,
} from './RuleParameterDto';

/**
 * 
 * @export
 * @interface RuleSetDto
 */
export interface RuleSetDto {
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    rulesetId?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    state?: RuleSetDtoStateEnum;
    /**
     * 
     * @type {Array<RuleDto>}
     * @memberof RuleSetDto
     */
    rules?: Array<RuleDto>;
    /**
     * 
     * @type {Set<string>}
     * @memberof RuleSetDto
     */
    allowedTransitions?: Set<RuleSetDtoAllowedTransitionsEnum>;
    /**
     * 
     * @type {Array<RuleParameterDto>}
     * @memberof RuleSetDto
     */
    parameters?: Array<RuleParameterDto>;
    /**
     * 
     * @type {Array<RuleSetDunningLevelDto>}
     * @memberof RuleSetDto
     */
    dunningLevels?: Array<RuleSetDunningLevelDto>;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    lastEditor?: string;
    /**
     * 
     * @type {RuleSetInfoDto}
     * @memberof RuleSetDto
     */
    subsequentRuleset?: RuleSetInfoDto;
    /**
     * 
     * @type {string}
     * @memberof RuleSetDto
     */
    readonly id?: string;
}


/**
 * @export
 */
export const RuleSetDtoStateEnum = {
    Active: 'ACTIVE',
    Edit: 'EDIT',
    Deleted: 'DELETED'
} as const;
export type RuleSetDtoStateEnum = typeof RuleSetDtoStateEnum[keyof typeof RuleSetDtoStateEnum];

/**
 * @export
 */
export const RuleSetDtoAllowedTransitionsEnum = {
    AddRule: 'ADD_RULE',
    DeleteRule: 'DELETE_RULE',
    UpdateRule: 'UPDATE_RULE',
    MakeActive: 'MAKE_ACTIVE',
    Delete: 'DELETE',
    SetToDefault: 'SET_TO_DEFAULT',
    Archive: 'ARCHIVE',
    ChangeName: 'CHANGE_NAME',
    ChangeDescription: 'CHANGE_DESCRIPTION',
    UpdateRuleSet: 'UPDATE_RULE_SET',
    ChangeRuleName: 'CHANGE_RULE_NAME',
    ChangeRuleDescription: 'CHANGE_RULE_DESCRIPTION'
} as const;
export type RuleSetDtoAllowedTransitionsEnum = typeof RuleSetDtoAllowedTransitionsEnum[keyof typeof RuleSetDtoAllowedTransitionsEnum];


/**
 * Check if a given object implements the RuleSetDto interface.
 */
export function instanceOfRuleSetDto(value: object): value is RuleSetDto {
    return true;
}

export function RuleSetDtoFromJSON(json: any): RuleSetDto {
    return RuleSetDtoFromJSONTyped(json, false);
}

export function RuleSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rulesetId': json['rulesetId'] == null ? undefined : json['rulesetId'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'state': json['state'] == null ? undefined : json['state'],
        'rules': json['rules'] == null ? undefined : ((json['rules'] as Array<any>).map(RuleDtoFromJSON)),
        'allowedTransitions': json['allowedTransitions'] == null ? undefined : json['allowedTransitions'],
        'parameters': json['parameters'] == null ? undefined : ((json['parameters'] as Array<any>).map(RuleParameterDtoFromJSON)),
        'dunningLevels': json['dunningLevels'] == null ? undefined : ((json['dunningLevels'] as Array<any>).map(RuleSetDunningLevelDtoFromJSON)),
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'lastEditor': json['lastEditor'] == null ? undefined : json['lastEditor'],
        'subsequentRuleset': json['subsequentRuleset'] == null ? undefined : RuleSetInfoDtoFromJSON(json['subsequentRuleset']),
        'id': json['id'] == null ? undefined : json['id'],
    };
}

export function RuleSetDtoToJSON(value?: Omit<RuleSetDto, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rulesetId': value['rulesetId'],
        'name': value['name'],
        'description': value['description'],
        'state': value['state'],
        'rules': value['rules'] == null ? undefined : ((value['rules'] as Array<any>).map(RuleDtoToJSON)),
        'allowedTransitions': value['allowedTransitions'] == null ? undefined : Array.from(value['allowedTransitions'] as Set<any>),
        'parameters': value['parameters'] == null ? undefined : ((value['parameters'] as Array<any>).map(RuleParameterDtoToJSON)),
        'dunningLevels': value['dunningLevels'] == null ? undefined : ((value['dunningLevels'] as Array<any>).map(RuleSetDunningLevelDtoToJSON)),
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
        'updated': value['updated'] == null ? undefined : ((value['updated']).toISOString()),
        'lastEditor': value['lastEditor'],
        'subsequentRuleset': RuleSetInfoDtoToJSON(value['subsequentRuleset']),
    };
}

