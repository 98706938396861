/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForecastActionDto
 */
export interface ForecastActionDto {
    /**
     * Number of notifications
     * @type {number}
     * @memberof ForecastActionDto
     */
    notifications?: number;
    /**
     * Number of disconnections
     * @type {number}
     * @memberof ForecastActionDto
     */
    disconnections?: number;
    /**
     * Number of disconnectionsThreat
     * @type {number}
     * @memberof ForecastActionDto
     */
    disconnectionsThreat?: number;
    /**
     * Number of disconnect announcement
     * @type {number}
     * @memberof ForecastActionDto
     */
    disconnectionsAnnouncement?: number;
    /**
     * Number of contractTermination
     * @type {number}
     * @memberof ForecastActionDto
     */
    contractTermination?: number;
    /**
     * Total open Amount
     * @type {number}
     * @memberof ForecastActionDto
     */
    totalOpenAmount?: number;
    /**
     * Total number of forecast actions
     * @type {number}
     * @memberof ForecastActionDto
     */
    total?: number;
}

/**
 * Check if a given object implements the ForecastActionDto interface.
 */
export function instanceOfForecastActionDto(value: object): value is ForecastActionDto {
    return true;
}

export function ForecastActionDtoFromJSON(json: any): ForecastActionDto {
    return ForecastActionDtoFromJSONTyped(json, false);
}

export function ForecastActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastActionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'notifications': json['notifications'] == null ? undefined : json['notifications'],
        'disconnections': json['disconnections'] == null ? undefined : json['disconnections'],
        'disconnectionsThreat': json['disconnectionsThreat'] == null ? undefined : json['disconnectionsThreat'],
        'disconnectionsAnnouncement': json['disconnectionsAnnouncement'] == null ? undefined : json['disconnectionsAnnouncement'],
        'contractTermination': json['contractTermination'] == null ? undefined : json['contractTermination'],
        'totalOpenAmount': json['totalOpenAmount'] == null ? undefined : json['totalOpenAmount'],
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function ForecastActionDtoToJSON(value?: ForecastActionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notifications': value['notifications'],
        'disconnections': value['disconnections'],
        'disconnectionsThreat': value['disconnectionsThreat'],
        'disconnectionsAnnouncement': value['disconnectionsAnnouncement'],
        'contractTermination': value['contractTermination'],
        'totalOpenAmount': value['totalOpenAmount'],
        'total': value['total'],
    };
}

