/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetFilterDto
 */
export interface RuleSetFilterDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleSetFilterDto
     */
    states?: Array<RuleSetFilterDtoStatesEnum>;
    /**
     * Set true to exclude default ruleSets; default False
     * @type {boolean}
     * @memberof RuleSetFilterDto
     */
    excludeDefault?: boolean;
}


/**
 * @export
 */
export const RuleSetFilterDtoStatesEnum = {
    Active: 'ACTIVE',
    Edit: 'EDIT',
    Deleted: 'DELETED'
} as const;
export type RuleSetFilterDtoStatesEnum = typeof RuleSetFilterDtoStatesEnum[keyof typeof RuleSetFilterDtoStatesEnum];


/**
 * Check if a given object implements the RuleSetFilterDto interface.
 */
export function instanceOfRuleSetFilterDto(value: object): value is RuleSetFilterDto {
    return true;
}

export function RuleSetFilterDtoFromJSON(json: any): RuleSetFilterDto {
    return RuleSetFilterDtoFromJSONTyped(json, false);
}

export function RuleSetFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetFilterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'states': json['states'] == null ? undefined : json['states'],
        'excludeDefault': json['excludeDefault'] == null ? undefined : json['excludeDefault'],
    };
}

export function RuleSetFilterDtoToJSON(value?: RuleSetFilterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'states': value['states'],
        'excludeDefault': value['excludeDefault'],
    };
}

