/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupSelector } from './GroupSelector';
import {
    GroupSelectorFromJSON,
    GroupSelectorFromJSONTyped,
    GroupSelectorToJSON,
} from './GroupSelector';

/**
 * 
 * @export
 * @interface AggregConditionDto
 */
export interface AggregConditionDto {
    /**
     * 
     * @type {string}
     * @memberof AggregConditionDto
     */
    aggregFunction: AggregConditionDtoAggregFunctionEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregConditionDto
     */
    attrib: AggregConditionDtoAttribEnum;
    /**
     * 
     * @type {string}
     * @memberof AggregConditionDto
     */
    cmp: AggregConditionDtoCmpEnum;
    /**
     * 
     * @type {object}
     * @memberof AggregConditionDto
     */
    argument: object;
    /**
     * 
     * @type {Array<GroupSelector>}
     * @memberof AggregConditionDto
     */
    customGrouping?: Array<GroupSelector>;
}


/**
 * @export
 */
export const AggregConditionDtoAggregFunctionEnum = {
    Sum: 'SUM'
} as const;
export type AggregConditionDtoAggregFunctionEnum = typeof AggregConditionDtoAggregFunctionEnum[keyof typeof AggregConditionDtoAggregFunctionEnum];

/**
 * @export
 */
export const AggregConditionDtoAttribEnum = {
    Level: 'LEVEL',
    OverdueWorkingDays: 'OVERDUE_WORKING_DAYS',
    OverdueCalendarDays: 'OVERDUE_CALENDAR_DAYS',
    OpenAmount: 'OPEN_AMOUNT',
    GrossAmount: 'GROSS_AMOUNT',
    SettledAmount: 'SETTLED_AMOUNT'
} as const;
export type AggregConditionDtoAttribEnum = typeof AggregConditionDtoAttribEnum[keyof typeof AggregConditionDtoAttribEnum];

/**
 * @export
 */
export const AggregConditionDtoCmpEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Ge: 'GE',
    Lt: 'LT',
    Le: 'LE'
} as const;
export type AggregConditionDtoCmpEnum = typeof AggregConditionDtoCmpEnum[keyof typeof AggregConditionDtoCmpEnum];


/**
 * Check if a given object implements the AggregConditionDto interface.
 */
export function instanceOfAggregConditionDto(value: object): value is AggregConditionDto {
    if (!('aggregFunction' in value) || value['aggregFunction'] === undefined) return false;
    if (!('attrib' in value) || value['attrib'] === undefined) return false;
    if (!('cmp' in value) || value['cmp'] === undefined) return false;
    if (!('argument' in value) || value['argument'] === undefined) return false;
    return true;
}

export function AggregConditionDtoFromJSON(json: any): AggregConditionDto {
    return AggregConditionDtoFromJSONTyped(json, false);
}

export function AggregConditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregConditionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'aggregFunction': json['aggregFunction'],
        'attrib': json['attrib'],
        'cmp': json['cmp'],
        'argument': json['argument'],
        'customGrouping': json['customGrouping'] == null ? undefined : ((json['customGrouping'] as Array<any>).map(GroupSelectorFromJSON)),
    };
}

export function AggregConditionDtoToJSON(value?: AggregConditionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aggregFunction': value['aggregFunction'],
        'attrib': value['attrib'],
        'cmp': value['cmp'],
        'argument': value['argument'],
        'customGrouping': value['customGrouping'] == null ? undefined : ((value['customGrouping'] as Array<any>).map(GroupSelectorToJSON)),
    };
}

