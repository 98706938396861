/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DunningSelectionConfigurationDto
 */
export interface DunningSelectionConfigurationDto {
    /**
     * Dunning selection is active
     * @type {boolean}
     * @memberof DunningSelectionConfigurationDto
     */
    active?: boolean;
    /**
     * Execution type: ONCE, PERMANENT, WORKING_DAYS
     * @type {string}
     * @memberof DunningSelectionConfigurationDto
     */
    executionType: DunningSelectionConfigurationDtoExecutionTypeEnum;
    /**
     * The date this execution should be valid
     * @type {Date}
     * @memberof DunningSelectionConfigurationDto
     */
    planedExecutionDate?: Date;
    /**
     * Payment type: all, direct debit, self payer
     * @type {string}
     * @memberof DunningSelectionConfigurationDto
     */
    paymentType?: DunningSelectionConfigurationDtoPaymentTypeEnum;
    /**
     * Contract states: done,blocked,delivery_net,canceled,waiting,active,cancellation_previous,terminated,expired,open
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    contractStates?: Set<DunningSelectionConfigurationDtoContractStatesEnum>;
    /**
     * The execution will be saved only if the procedure id assign to the contract is present in this dunningProcedures or dunningProcedures is empty.
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    dunningProcedures?: Set<string>;
    /**
     * Max number of notification
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxNotifications?: number;
    /**
     * Max number of contract terminations
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxTerminations?: number;
    /**
     * Max number of threads
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxThreats?: number;
    /**
     * Max number of contract announcements
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxAnnouncements?: number;
    /**
     * Max number of contract disconnections
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    maxDisconnection?: number;
    /**
     * If contract termination is triggered for an execution, the execution will be saved only if the procedure id is found in the terminationProcedures or terminationProcedures is empty
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    terminationProcedures?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof DunningSelectionConfigurationDto
     */
    minOpenAmount?: number;
    /**
     * Rules that should be taken into account during execution. If empty, all rules will be applied
     * @type {Set<string>}
     * @memberof DunningSelectionConfigurationDto
     */
    rules?: Set<string>;
    /**
     * Exclude overdue receivables based on the due date limitation
     * @type {Date}
     * @memberof DunningSelectionConfigurationDto
     */
    dueDateLimitation?: Date;
}


/**
 * @export
 */
export const DunningSelectionConfigurationDtoExecutionTypeEnum = {
    Once: 'ONCE',
    Permanent: 'PERMANENT',
    WorkingDays: 'WORKING_DAYS'
} as const;
export type DunningSelectionConfigurationDtoExecutionTypeEnum = typeof DunningSelectionConfigurationDtoExecutionTypeEnum[keyof typeof DunningSelectionConfigurationDtoExecutionTypeEnum];

/**
 * @export
 */
export const DunningSelectionConfigurationDtoPaymentTypeEnum = {
    All: 'ALL',
    DirectDebit: 'DIRECT_DEBIT',
    SelfPayer: 'SELF_PAYER'
} as const;
export type DunningSelectionConfigurationDtoPaymentTypeEnum = typeof DunningSelectionConfigurationDtoPaymentTypeEnum[keyof typeof DunningSelectionConfigurationDtoPaymentTypeEnum];

/**
 * @export
 */
export const DunningSelectionConfigurationDtoContractStatesEnum = {
    Open: 'OPEN',
    Active: 'ACTIVE',
    Blocked: 'BLOCKED',
    Waiting: 'WAITING',
    Canceled: 'CANCELED',
    CancellationPrevious: 'CANCELLATION_PREVIOUS',
    DeliveryNet: 'DELIVERY_NET',
    Terminated: 'TERMINATED',
    Expired: 'EXPIRED',
    Done: 'DONE'
} as const;
export type DunningSelectionConfigurationDtoContractStatesEnum = typeof DunningSelectionConfigurationDtoContractStatesEnum[keyof typeof DunningSelectionConfigurationDtoContractStatesEnum];


/**
 * Check if a given object implements the DunningSelectionConfigurationDto interface.
 */
export function instanceOfDunningSelectionConfigurationDto(value: object): value is DunningSelectionConfigurationDto {
    if (!('executionType' in value) || value['executionType'] === undefined) return false;
    return true;
}

export function DunningSelectionConfigurationDtoFromJSON(json: any): DunningSelectionConfigurationDto {
    return DunningSelectionConfigurationDtoFromJSONTyped(json, false);
}

export function DunningSelectionConfigurationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DunningSelectionConfigurationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'] == null ? undefined : json['active'],
        'executionType': json['executionType'],
        'planedExecutionDate': json['planedExecutionDate'] == null ? undefined : (new Date(json['planedExecutionDate'])),
        'paymentType': json['paymentType'] == null ? undefined : json['paymentType'],
        'contractStates': json['contractStates'] == null ? undefined : json['contractStates'],
        'dunningProcedures': json['dunningProcedures'] == null ? undefined : json['dunningProcedures'],
        'maxNotifications': json['maxNotifications'] == null ? undefined : json['maxNotifications'],
        'maxTerminations': json['maxTerminations'] == null ? undefined : json['maxTerminations'],
        'maxThreats': json['maxThreats'] == null ? undefined : json['maxThreats'],
        'maxAnnouncements': json['maxAnnouncements'] == null ? undefined : json['maxAnnouncements'],
        'maxDisconnection': json['maxDisconnection'] == null ? undefined : json['maxDisconnection'],
        'terminationProcedures': json['terminationProcedures'] == null ? undefined : json['terminationProcedures'],
        'minOpenAmount': json['minOpenAmount'] == null ? undefined : json['minOpenAmount'],
        'rules': json['rules'] == null ? undefined : json['rules'],
        'dueDateLimitation': json['dueDateLimitation'] == null ? undefined : (new Date(json['dueDateLimitation'])),
    };
}

export function DunningSelectionConfigurationDtoToJSON(value?: DunningSelectionConfigurationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'active': value['active'],
        'executionType': value['executionType'],
        'planedExecutionDate': value['planedExecutionDate'] == null ? undefined : ((value['planedExecutionDate']).toISOString().substring(0,10)),
        'paymentType': value['paymentType'],
        'contractStates': value['contractStates'] == null ? undefined : Array.from(value['contractStates'] as Set<any>),
        'dunningProcedures': value['dunningProcedures'] == null ? undefined : Array.from(value['dunningProcedures'] as Set<any>),
        'maxNotifications': value['maxNotifications'],
        'maxTerminations': value['maxTerminations'],
        'maxThreats': value['maxThreats'],
        'maxAnnouncements': value['maxAnnouncements'],
        'maxDisconnection': value['maxDisconnection'],
        'terminationProcedures': value['terminationProcedures'] == null ? undefined : Array.from(value['terminationProcedures'] as Set<any>),
        'minOpenAmount': value['minOpenAmount'],
        'rules': value['rules'] == null ? undefined : Array.from(value['rules'] as Set<any>),
        'dueDateLimitation': value['dueDateLimitation'] == null ? undefined : ((value['dueDateLimitation']).toISOString().substring(0,10)),
    };
}

