/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContractStateOverview } from './ContractStateOverview';
import {
    ContractStateOverviewFromJSON,
    ContractStateOverviewFromJSONTyped,
    ContractStateOverviewToJSON,
} from './ContractStateOverview';

/**
 * 
 * @export
 * @interface PaginatedContractStateOverview
 */
export interface PaginatedContractStateOverview {
    /**
     * 
     * @type {Array<ContractStateOverview>}
     * @memberof PaginatedContractStateOverview
     */
    data?: Array<ContractStateOverview>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedContractStateOverview
     */
    totalAmountOfItems?: number;
}

/**
 * Check if a given object implements the PaginatedContractStateOverview interface.
 */
export function instanceOfPaginatedContractStateOverview(value: object): value is PaginatedContractStateOverview {
    return true;
}

export function PaginatedContractStateOverviewFromJSON(json: any): PaginatedContractStateOverview {
    return PaginatedContractStateOverviewFromJSONTyped(json, false);
}

export function PaginatedContractStateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedContractStateOverview {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(ContractStateOverviewFromJSON)),
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'totalAmountOfItems': json['totalAmountOfItems'] == null ? undefined : json['totalAmountOfItems'],
    };
}

export function PaginatedContractStateOverviewToJSON(value?: PaginatedContractStateOverview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(ContractStateOverviewToJSON)),
        'pageNumber': value['pageNumber'],
        'pageSize': value['pageSize'],
        'totalAmountOfItems': value['totalAmountOfItems'],
    };
}

