/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetCustomizationSyncResponseDto
 */
export interface RuleSetCustomizationSyncResponseDto {
    /**
     * 
     * @type {number}
     * @memberof RuleSetCustomizationSyncResponseDto
     */
    customizationCount: number;
}

/**
 * Check if a given object implements the RuleSetCustomizationSyncResponseDto interface.
 */
export function instanceOfRuleSetCustomizationSyncResponseDto(value: object): value is RuleSetCustomizationSyncResponseDto {
    if (!('customizationCount' in value) || value['customizationCount'] === undefined) return false;
    return true;
}

export function RuleSetCustomizationSyncResponseDtoFromJSON(json: any): RuleSetCustomizationSyncResponseDto {
    return RuleSetCustomizationSyncResponseDtoFromJSONTyped(json, false);
}

export function RuleSetCustomizationSyncResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetCustomizationSyncResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'customizationCount': json['customizationCount'],
    };
}

export function RuleSetCustomizationSyncResponseDtoToJSON(value?: RuleSetCustomizationSyncResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customizationCount': value['customizationCount'],
    };
}

