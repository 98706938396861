/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleAdviceDto
 */
export interface RuleAdviceDto {
    /**
     * 
     * @type {string}
     * @memberof RuleAdviceDto
     */
    advice?: string;
}

/**
 * Check if a given object implements the RuleAdviceDto interface.
 */
export function instanceOfRuleAdviceDto(value: object): value is RuleAdviceDto {
    return true;
}

export function RuleAdviceDtoFromJSON(json: any): RuleAdviceDto {
    return RuleAdviceDtoFromJSONTyped(json, false);
}

export function RuleAdviceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleAdviceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'advice': json['advice'] == null ? undefined : json['advice'],
    };
}

export function RuleAdviceDtoToJSON(value?: RuleAdviceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'advice': value['advice'],
    };
}

