/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleOrderDto
 */
export interface RuleOrderDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleOrderDto
     */
    orderedRuleIds: Array<string>;
}

/**
 * Check if a given object implements the RuleOrderDto interface.
 */
export function instanceOfRuleOrderDto(value: object): value is RuleOrderDto {
    if (!('orderedRuleIds' in value) || value['orderedRuleIds'] === undefined) return false;
    return true;
}

export function RuleOrderDtoFromJSON(json: any): RuleOrderDto {
    return RuleOrderDtoFromJSONTyped(json, false);
}

export function RuleOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleOrderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'orderedRuleIds': json['orderedRuleIds'],
    };
}

export function RuleOrderDtoToJSON(value?: RuleOrderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderedRuleIds': value['orderedRuleIds'],
    };
}

