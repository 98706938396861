/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DunningSelectionConfigurationDto,
  ErrorResponse,
  ForecastDto,
} from '../models/index';
import {
    DunningSelectionConfigurationDtoFromJSON,
    DunningSelectionConfigurationDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ForecastDtoFromJSON,
    ForecastDtoToJSON,
} from '../models/index';

export interface DownloadRequest {
    dunningSelectionConfigurationDto: DunningSelectionConfigurationDto;
}

export interface GetForecastByConfigurationDtoRequest {
    dunningSelectionConfigurationDto: DunningSelectionConfigurationDto;
}

/**
 * 
 */
export class ForecastApi extends runtime.BaseAPI {

    /**
     * Download a CSV file with information about the fulfillment forecast. CSV file columns: `contractId`, `rulesetName`, `actions`, `openAmount`. 
     * Download CSV file
     */
    async downloadRaw(requestParameters: DownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['dunningSelectionConfigurationDto'] == null) {
            throw new runtime.RequiredError(
                'dunningSelectionConfigurationDto',
                'Required parameter "dunningSelectionConfigurationDto" was null or undefined when calling download().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forecast/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DunningSelectionConfigurationDtoToJSON(requestParameters['dunningSelectionConfigurationDto']),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a CSV file with information about the fulfillment forecast. CSV file columns: `contractId`, `rulesetName`, `actions`, `openAmount`. 
     * Download CSV file
     */
    async download(requestParameters: DownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a summary of the execution forecast.
     * Get Forecast
     */
    async getForecastByConfigurationDtoRaw(requestParameters: GetForecastByConfigurationDtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForecastDto>> {
        if (requestParameters['dunningSelectionConfigurationDto'] == null) {
            throw new runtime.RequiredError(
                'dunningSelectionConfigurationDto',
                'Required parameter "dunningSelectionConfigurationDto" was null or undefined when calling getForecastByConfigurationDto().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forecast`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DunningSelectionConfigurationDtoToJSON(requestParameters['dunningSelectionConfigurationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForecastDtoFromJSON(jsonValue));
    }

    /**
     * Get a summary of the execution forecast.
     * Get Forecast
     */
    async getForecastByConfigurationDto(requestParameters: GetForecastByConfigurationDtoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForecastDto> {
        const response = await this.getForecastByConfigurationDtoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
