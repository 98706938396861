/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetCustomizationSyncDto
 */
export interface RuleSetCustomizationSyncDto {
    /**
     * 
     * @type {string}
     * @memberof RuleSetCustomizationSyncDto
     */
    scope: RuleSetCustomizationSyncDtoScopeEnum;
}


/**
 * @export
 */
export const RuleSetCustomizationSyncDtoScopeEnum = {
    Full: 'FULL',
    Active: 'ACTIVE',
    Passive: 'PASSIVE'
} as const;
export type RuleSetCustomizationSyncDtoScopeEnum = typeof RuleSetCustomizationSyncDtoScopeEnum[keyof typeof RuleSetCustomizationSyncDtoScopeEnum];


/**
 * Check if a given object implements the RuleSetCustomizationSyncDto interface.
 */
export function instanceOfRuleSetCustomizationSyncDto(value: object): value is RuleSetCustomizationSyncDto {
    if (!('scope' in value) || value['scope'] === undefined) return false;
    return true;
}

export function RuleSetCustomizationSyncDtoFromJSON(json: any): RuleSetCustomizationSyncDto {
    return RuleSetCustomizationSyncDtoFromJSONTyped(json, false);
}

export function RuleSetCustomizationSyncDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetCustomizationSyncDto {
    if (json == null) {
        return json;
    }
    return {
        
        'scope': json['scope'],
    };
}

export function RuleSetCustomizationSyncDtoToJSON(value?: RuleSetCustomizationSyncDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scope': value['scope'],
    };
}

