/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutionOutcomeDto
 */
export interface ExecutionOutcomeDto {
    /**
     * The id of the outcome, aka action
     * @type {string}
     * @memberof ExecutionOutcomeDto
     */
    id?: string;
    /**
     * The executed action
     * @type {string}
     * @memberof ExecutionOutcomeDto
     * @deprecated
     */
    actionInfo?: string;
    /**
     * The executed action type
     * @type {string}
     * @memberof ExecutionOutcomeDto
     */
    actionType?: string;
    /**
     * The state of the execution for the given action
     * @type {string}
     * @memberof ExecutionOutcomeDto
     */
    executionState?: ExecutionOutcomeDtoExecutionStateEnum;
    /**
     * The contract ids associated with this outcome
     * @type {Array<number>}
     * @memberof ExecutionOutcomeDto
     */
    contractIds?: Array<number>;
    /**
     * The receivable ids associated with this outcome
     * @type {Array<number>}
     * @memberof ExecutionOutcomeDto
     */
    receivableIds?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ExecutionOutcomeDto
     */
    properties?: { [key: string]: object; };
    /**
     * Last time the outcome was retried
     * @type {Date}
     * @memberof ExecutionOutcomeDto
     */
    lastModified?: Date;
    /**
     * Last user who retried the outcome
     * @type {string}
     * @memberof ExecutionOutcomeDto
     */
    lastModifiedUser?: string;
    /**
     * The name of the rule used in the execution outcome
     * @type {string}
     * @memberof ExecutionOutcomeDto
     */
    ruleName?: string;
}


/**
 * @export
 */
export const ExecutionOutcomeDtoExecutionStateEnum = {
    Pending: 'PENDING',
    AwaitingSiblings: 'AWAITING_SIBLINGS',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type ExecutionOutcomeDtoExecutionStateEnum = typeof ExecutionOutcomeDtoExecutionStateEnum[keyof typeof ExecutionOutcomeDtoExecutionStateEnum];


/**
 * Check if a given object implements the ExecutionOutcomeDto interface.
 */
export function instanceOfExecutionOutcomeDto(value: object): value is ExecutionOutcomeDto {
    return true;
}

export function ExecutionOutcomeDtoFromJSON(json: any): ExecutionOutcomeDto {
    return ExecutionOutcomeDtoFromJSONTyped(json, false);
}

export function ExecutionOutcomeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionOutcomeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'actionInfo': json['actionInfo'] == null ? undefined : json['actionInfo'],
        'actionType': json['actionType'] == null ? undefined : json['actionType'],
        'executionState': json['executionState'] == null ? undefined : json['executionState'],
        'contractIds': json['contractIds'] == null ? undefined : json['contractIds'],
        'receivableIds': json['receivableIds'] == null ? undefined : json['receivableIds'],
        'properties': json['properties'] == null ? undefined : json['properties'],
        'lastModified': json['lastModified'] == null ? undefined : (new Date(json['lastModified'])),
        'lastModifiedUser': json['lastModifiedUser'] == null ? undefined : json['lastModifiedUser'],
        'ruleName': json['ruleName'] == null ? undefined : json['ruleName'],
    };
}

export function ExecutionOutcomeDtoToJSON(value?: ExecutionOutcomeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'actionInfo': value['actionInfo'],
        'actionType': value['actionType'],
        'executionState': value['executionState'],
        'contractIds': value['contractIds'],
        'receivableIds': value['receivableIds'],
        'properties': value['properties'],
        'lastModified': value['lastModified'] == null ? undefined : ((value['lastModified']).toISOString()),
        'lastModifiedUser': value['lastModifiedUser'],
        'ruleName': value['ruleName'],
    };
}

