/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemPropertyDto
 */
export interface SystemPropertyDto {
    /**
     * The property key identifier
     * @type {string}
     * @memberof SystemPropertyDto
     */
    property?: string;
    /**
     * The value for the property
     * @type {string}
     * @memberof SystemPropertyDto
     */
    value?: string;
    /**
     * The type that value is: INTEGER, STRING or BOOLEAN
     * @type {string}
     * @memberof SystemPropertyDto
     */
    type?: SystemPropertyDtoTypeEnum;
    /**
     * True if the field should have a value, False vice versa
     * @type {boolean}
     * @memberof SystemPropertyDto
     */
    requiredField: boolean;
}


/**
 * @export
 */
export const SystemPropertyDtoTypeEnum = {
    String: 'STRING',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Boolean: 'BOOLEAN'
} as const;
export type SystemPropertyDtoTypeEnum = typeof SystemPropertyDtoTypeEnum[keyof typeof SystemPropertyDtoTypeEnum];


/**
 * Check if a given object implements the SystemPropertyDto interface.
 */
export function instanceOfSystemPropertyDto(value: object): value is SystemPropertyDto {
    if (!('requiredField' in value) || value['requiredField'] === undefined) return false;
    return true;
}

export function SystemPropertyDtoFromJSON(json: any): SystemPropertyDto {
    return SystemPropertyDtoFromJSONTyped(json, false);
}

export function SystemPropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemPropertyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'property': json['property'] == null ? undefined : json['property'],
        'value': json['value'] == null ? undefined : json['value'],
        'type': json['type'] == null ? undefined : json['type'],
        'requiredField': json['requiredField'],
    };
}

export function SystemPropertyDtoToJSON(value?: SystemPropertyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'property': value['property'],
        'value': value['value'],
        'type': value['type'],
        'requiredField': value['requiredField'],
    };
}

