/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DunningLevelDto
 */
export interface DunningLevelDto {
    /**
     * The Level name
     * @type {string}
     * @memberof DunningLevelDto
     */
    name: string;
    /**
     * The description of level
     * @type {string}
     * @memberof DunningLevelDto
     */
    description?: string;
    /**
     * Id of dunning level
     * @type {string}
     * @memberof DunningLevelDto
     */
    id: string;
}

/**
 * Check if a given object implements the DunningLevelDto interface.
 */
export function instanceOfDunningLevelDto(value: object): value is DunningLevelDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function DunningLevelDtoFromJSON(json: any): DunningLevelDto {
    return DunningLevelDtoFromJSONTyped(json, false);
}

export function DunningLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DunningLevelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'id': json['id'],
    };
}

export function DunningLevelDtoToJSON(value?: DunningLevelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'id': value['id'],
    };
}

