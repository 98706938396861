/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleParameterDto
 */
export interface RuleParameterDto {
    /**
     * 
     * @type {string}
     * @memberof RuleParameterDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleParameterDto
     */
    value: number;
}

/**
 * Check if a given object implements the RuleParameterDto interface.
 */
export function instanceOfRuleParameterDto(value: object): value is RuleParameterDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function RuleParameterDtoFromJSON(json: any): RuleParameterDto {
    return RuleParameterDtoFromJSONTyped(json, false);
}

export function RuleParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'value': json['value'],
    };
}

export function RuleParameterDtoToJSON(value?: RuleParameterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'value': value['value'],
    };
}

