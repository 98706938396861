/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancellationResultDto
 */
export interface CancellationResultDto {
    /**
     * If the cancellation was done as a dry run
     * @type {boolean}
     * @memberof CancellationResultDto
     */
    dryRun?: boolean;
    /**
     * The ids of the cancelled executions
     * @type {Array<string>}
     * @memberof CancellationResultDto
     */
    canceledExecutions?: Array<string>;
    /**
     * Receivable IDs and their new level id after cancellation
     * @type {{ [key: string]: string; }}
     * @memberof CancellationResultDto
     */
    updatedReceivableLevels?: { [key: string]: string; };
    /**
     * Receivable IDs and their settlement date after cancellation
     * @type {{ [key: string]: Date; }}
     * @memberof CancellationResultDto
     */
    updatedReceivableSettlementDeadlines?: { [key: string]: Date; };
}

/**
 * Check if a given object implements the CancellationResultDto interface.
 */
export function instanceOfCancellationResultDto(value: object): value is CancellationResultDto {
    return true;
}

export function CancellationResultDtoFromJSON(json: any): CancellationResultDto {
    return CancellationResultDtoFromJSONTyped(json, false);
}

export function CancellationResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dryRun': json['dryRun'] == null ? undefined : json['dryRun'],
        'canceledExecutions': json['canceledExecutions'] == null ? undefined : json['canceledExecutions'],
        'updatedReceivableLevels': json['updatedReceivableLevels'] == null ? undefined : json['updatedReceivableLevels'],
        'updatedReceivableSettlementDeadlines': json['updatedReceivableSettlementDeadlines'] == null ? undefined : json['updatedReceivableSettlementDeadlines'],
    };
}

export function CancellationResultDtoToJSON(value?: CancellationResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dryRun': value['dryRun'],
        'canceledExecutions': value['canceledExecutions'],
        'updatedReceivableLevels': value['updatedReceivableLevels'],
        'updatedReceivableSettlementDeadlines': value['updatedReceivableSettlementDeadlines'],
    };
}

