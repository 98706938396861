/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerCaseConditionDto
 */
export interface CustomerCaseConditionDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerCaseConditionDto
     */
    attrib: CustomerCaseConditionDtoAttribEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerCaseConditionDto
     */
    cmp: CustomerCaseConditionDtoCmpEnum;
    /**
     * 
     * @type {object}
     * @memberof CustomerCaseConditionDto
     */
    argument: object;
    /**
     * 
     * @type {string}
     * @memberof CustomerCaseConditionDto
     */
    attribExtension?: string;
}


/**
 * @export
 */
export const CustomerCaseConditionDtoAttribEnum = {
    WorkingDaysSinceLastExecution: 'WORKING_DAYS_SINCE_LAST_EXECUTION',
    CalendarDaysSinceLastExecution: 'CALENDAR_DAYS_SINCE_LAST_EXECUTION',
    WorkingDaysSinceLastNotification: 'WORKING_DAYS_SINCE_LAST_NOTIFICATION',
    CalendarDaysSinceLastNotification: 'CALENDAR_DAYS_SINCE_LAST_NOTIFICATION',
    CustomerGroup: 'CUSTOMER_GROUP',
    CustomerType: 'CUSTOMER_TYPE'
} as const;
export type CustomerCaseConditionDtoAttribEnum = typeof CustomerCaseConditionDtoAttribEnum[keyof typeof CustomerCaseConditionDtoAttribEnum];

/**
 * @export
 */
export const CustomerCaseConditionDtoCmpEnum = {
    Eq: 'EQ',
    Neq: 'NEQ',
    Gt: 'GT',
    Ge: 'GE',
    Lt: 'LT',
    Le: 'LE'
} as const;
export type CustomerCaseConditionDtoCmpEnum = typeof CustomerCaseConditionDtoCmpEnum[keyof typeof CustomerCaseConditionDtoCmpEnum];


/**
 * Check if a given object implements the CustomerCaseConditionDto interface.
 */
export function instanceOfCustomerCaseConditionDto(value: object): value is CustomerCaseConditionDto {
    if (!('attrib' in value) || value['attrib'] === undefined) return false;
    if (!('cmp' in value) || value['cmp'] === undefined) return false;
    if (!('argument' in value) || value['argument'] === undefined) return false;
    return true;
}

export function CustomerCaseConditionDtoFromJSON(json: any): CustomerCaseConditionDto {
    return CustomerCaseConditionDtoFromJSONTyped(json, false);
}

export function CustomerCaseConditionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerCaseConditionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'attrib': json['attrib'],
        'cmp': json['cmp'],
        'argument': json['argument'],
        'attribExtension': json['attribExtension'] == null ? undefined : json['attribExtension'],
    };
}

export function CustomerCaseConditionDtoToJSON(value?: CustomerCaseConditionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attrib': value['attrib'],
        'cmp': value['cmp'],
        'argument': value['argument'],
        'attribExtension': value['attribExtension'],
    };
}

