/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlagConditionDto } from './FlagConditionDto';
import {
    FlagConditionDtoFromJSON,
    FlagConditionDtoFromJSONTyped,
    FlagConditionDtoToJSON,
} from './FlagConditionDto';
import type { AggregConditionDto } from './AggregConditionDto';
import {
    AggregConditionDtoFromJSON,
    AggregConditionDtoFromJSONTyped,
    AggregConditionDtoToJSON,
} from './AggregConditionDto';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';
import type { GroupSelectorDto } from './GroupSelectorDto';
import {
    GroupSelectorDtoFromJSON,
    GroupSelectorDtoFromJSONTyped,
    GroupSelectorDtoToJSON,
} from './GroupSelectorDto';

/**
 * 
 * @export
 * @interface RuleUpdateDto
 */
export interface RuleUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RuleUpdateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RuleUpdateDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RuleUpdateDto
     */
    orderValue?: number;
    /**
     * 
     * @type {Array<FlagConditionDto>}
     * @memberof RuleUpdateDto
     */
    flagConditions?: Array<FlagConditionDto>;
    /**
     * 
     * @type {Array<GroupSelectorDto>}
     * @memberof RuleUpdateDto
     */
    groupSelectors: Array<GroupSelectorDto>;
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleUpdateDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleUpdateDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
    /**
     * 
     * @type {Array<AggregConditionDto>}
     * @memberof RuleUpdateDto
     */
    aggregConditions: Array<AggregConditionDto>;
    /**
     * 
     * @type {object}
     * @memberof RuleUpdateDto
     */
    actions: object;
    /**
     * 
     * @type {Date}
     * @memberof RuleUpdateDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleUpdateDto
     */
    updated?: Date;
    /**
     * Indicates if the rule should be executed for each contract individually
     * @type {boolean}
     * @memberof RuleUpdateDto
     */
    executePerContract?: boolean;
}

/**
 * Check if a given object implements the RuleUpdateDto interface.
 */
export function instanceOfRuleUpdateDto(value: object): value is RuleUpdateDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('groupSelectors' in value) || value['groupSelectors'] === undefined) return false;
    if (!('caseConditions' in value) || value['caseConditions'] === undefined) return false;
    if (!('customerCaseConditions' in value) || value['customerCaseConditions'] === undefined) return false;
    if (!('aggregConditions' in value) || value['aggregConditions'] === undefined) return false;
    if (!('actions' in value) || value['actions'] === undefined) return false;
    return true;
}

export function RuleUpdateDtoFromJSON(json: any): RuleUpdateDto {
    return RuleUpdateDtoFromJSONTyped(json, false);
}

export function RuleUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'orderValue': json['orderValue'] == null ? undefined : json['orderValue'],
        'flagConditions': json['flagConditions'] == null ? undefined : ((json['flagConditions'] as Array<any>).map(FlagConditionDtoFromJSON)),
        'groupSelectors': ((json['groupSelectors'] as Array<any>).map(GroupSelectorDtoFromJSON)),
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
        'aggregConditions': ((json['aggregConditions'] as Array<any>).map(AggregConditionDtoFromJSON)),
        'actions': json['actions'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'executePerContract': json['executePerContract'] == null ? undefined : json['executePerContract'],
    };
}

export function RuleUpdateDtoToJSON(value?: RuleUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'orderValue': value['orderValue'],
        'flagConditions': value['flagConditions'] == null ? undefined : ((value['flagConditions'] as Array<any>).map(FlagConditionDtoToJSON)),
        'groupSelectors': ((value['groupSelectors'] as Array<any>).map(GroupSelectorDtoToJSON)),
        'caseConditions': ((value['caseConditions'] as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoToJSON)),
        'aggregConditions': ((value['aggregConditions'] as Array<any>).map(AggregConditionDtoToJSON)),
        'actions': value['actions'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
        'updated': value['updated'] == null ? undefined : ((value['updated']).toISOString()),
        'executePerContract': value['executePerContract'],
    };
}

