/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ForecastActionDto } from './ForecastActionDto';
import {
    ForecastActionDtoFromJSON,
    ForecastActionDtoFromJSONTyped,
    ForecastActionDtoToJSON,
} from './ForecastActionDto';

/**
 * 
 * @export
 * @interface ForecastDto
 */
export interface ForecastDto {
    /**
     * 
     * @type {ForecastActionDto}
     * @memberof ForecastDto
     */
    withoutDunningSelection?: ForecastActionDto;
    /**
     * 
     * @type {ForecastActionDto}
     * @memberof ForecastDto
     */
    withDunningSelection?: ForecastActionDto;
    /**
     * 
     * @type {Date}
     * @memberof ForecastDto
     */
    forecastedDate?: Date;
}

/**
 * Check if a given object implements the ForecastDto interface.
 */
export function instanceOfForecastDto(value: object): value is ForecastDto {
    return true;
}

export function ForecastDtoFromJSON(json: any): ForecastDto {
    return ForecastDtoFromJSONTyped(json, false);
}

export function ForecastDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForecastDto {
    if (json == null) {
        return json;
    }
    return {
        
        'withoutDunningSelection': json['withoutDunningSelection'] == null ? undefined : ForecastActionDtoFromJSON(json['withoutDunningSelection']),
        'withDunningSelection': json['withDunningSelection'] == null ? undefined : ForecastActionDtoFromJSON(json['withDunningSelection']),
        'forecastedDate': json['forecastedDate'] == null ? undefined : (new Date(json['forecastedDate'])),
    };
}

export function ForecastDtoToJSON(value?: ForecastDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'withoutDunningSelection': ForecastActionDtoToJSON(value['withoutDunningSelection']),
        'withDunningSelection': ForecastActionDtoToJSON(value['withDunningSelection']),
        'forecastedDate': value['forecastedDate'] == null ? undefined : ((value['forecastedDate']).toISOString().substring(0,10)),
    };
}

