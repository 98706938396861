/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetInfoDto
 */
export interface RuleSetInfoDto {
    /**
     * The id of the ruleset
     * @type {string}
     * @memberof RuleSetInfoDto
     */
    rulesetId?: string;
    /**
     * The new name for the ruleset
     * @type {string}
     * @memberof RuleSetInfoDto
     */
    name: string;
    /**
     * The new description for the ruleset
     * @type {string}
     * @memberof RuleSetInfoDto
     */
    description?: string;
}

/**
 * Check if a given object implements the RuleSetInfoDto interface.
 */
export function instanceOfRuleSetInfoDto(value: object): value is RuleSetInfoDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function RuleSetInfoDtoFromJSON(json: any): RuleSetInfoDto {
    return RuleSetInfoDtoFromJSONTyped(json, false);
}

export function RuleSetInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rulesetId': json['rulesetId'] == null ? undefined : json['rulesetId'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function RuleSetInfoDtoToJSON(value?: RuleSetInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rulesetId': value['rulesetId'],
        'name': value['name'],
        'description': value['description'],
    };
}

