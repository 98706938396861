/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClockCommandDto,
  ErrorResponse,
} from '../models/index';
import {
    ClockCommandDtoFromJSON,
    ClockCommandDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface IncreaseClockRequest {
    clockCommandDto: ClockCommandDto;
}

/**
 * 
 */
export class ClockApi extends runtime.BaseAPI {

    /**
     * The service has customizable clock that can be changed by increasing the offset a few days into the future. This is a development feature, MUST not be used in production! Please don\'t forget to enable `dev features` from database. 
     * Get service clock
     */
    async getClockRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/clock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * The service has customizable clock that can be changed by increasing the offset a few days into the future. This is a development feature, MUST not be used in production! Please don\'t forget to enable `dev features` from database. 
     * Get service clock
     */
    async getClock(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.getClockRaw(initOverrides);
        return await response.value();
    }

    /**
     * Increase the JDS clock by the number of days or reset clock.
     * Manipulate JDS clock on day level
     */
    async increaseClockRaw(requestParameters: IncreaseClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters['clockCommandDto'] == null) {
            throw new runtime.RequiredError(
                'clockCommandDto',
                'Required parameter "clockCommandDto" was null or undefined when calling increaseClock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/clock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClockCommandDtoToJSON(requestParameters['clockCommandDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Increase the JDS clock by the number of days or reset clock.
     * Manipulate JDS clock on day level
     */
    async increaseClock(requestParameters: IncreaseClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.increaseClockRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
