/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReceivableDunningStateDto
 */
export interface ReceivableDunningStateDto {
    /**
     * The id of the receivable
     * @type {number}
     * @memberof ReceivableDunningStateDto
     */
    receivableCoreId?: number;
    /**
     * The current level of the dunning process
     * @type {string}
     * @memberof ReceivableDunningStateDto
     */
    level?: string;
    /**
     * (Optional) The next settlement deadline for the receivable
     * @type {Date}
     * @memberof ReceivableDunningStateDto
     */
    settlementDeadline?: Date;
}

/**
 * Check if a given object implements the ReceivableDunningStateDto interface.
 */
export function instanceOfReceivableDunningStateDto(value: object): value is ReceivableDunningStateDto {
    return true;
}

export function ReceivableDunningStateDtoFromJSON(json: any): ReceivableDunningStateDto {
    return ReceivableDunningStateDtoFromJSONTyped(json, false);
}

export function ReceivableDunningStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceivableDunningStateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'receivableCoreId': json['receivableCoreId'] == null ? undefined : json['receivableCoreId'],
        'level': json['level'] == null ? undefined : json['level'],
        'settlementDeadline': json['settlementDeadline'] == null ? undefined : (new Date(json['settlementDeadline'])),
    };
}

export function ReceivableDunningStateDtoToJSON(value?: ReceivableDunningStateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'receivableCoreId': value['receivableCoreId'],
        'level': value['level'],
        'settlementDeadline': value['settlementDeadline'] == null ? undefined : ((value['settlementDeadline']).toISOString().substring(0,10)),
    };
}

