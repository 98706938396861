/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';

/**
 * 
 * @export
 * @interface RuleSetAssignmentCriteriaDto
 */
export interface RuleSetAssignmentCriteriaDto {
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
    /**
     * 
     * @type {Array<object>}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    actions?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    ruleSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RuleSetAssignmentCriteriaDto
     */
    lastEditor?: string;
}

/**
 * Check if a given object implements the RuleSetAssignmentCriteriaDto interface.
 */
export function instanceOfRuleSetAssignmentCriteriaDto(value: object): value is RuleSetAssignmentCriteriaDto {
    if (!('caseConditions' in value) || value['caseConditions'] === undefined) return false;
    if (!('customerCaseConditions' in value) || value['customerCaseConditions'] === undefined) return false;
    return true;
}

export function RuleSetAssignmentCriteriaDtoFromJSON(json: any): RuleSetAssignmentCriteriaDto {
    return RuleSetAssignmentCriteriaDtoFromJSONTyped(json, false);
}

export function RuleSetAssignmentCriteriaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetAssignmentCriteriaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
        'actions': json['actions'] == null ? undefined : json['actions'],
        'id': json['id'] == null ? undefined : json['id'],
        'ruleSetId': json['ruleSetId'] == null ? undefined : json['ruleSetId'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'lastEditor': json['lastEditor'] == null ? undefined : json['lastEditor'],
    };
}

export function RuleSetAssignmentCriteriaDtoToJSON(value?: RuleSetAssignmentCriteriaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'caseConditions': ((value['caseConditions'] as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoToJSON)),
        'actions': value['actions'],
        'id': value['id'],
        'ruleSetId': value['ruleSetId'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
        'updated': value['updated'] == null ? undefined : ((value['updated']).toISOString()),
        'lastEditor': value['lastEditor'],
    };
}

