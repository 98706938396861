/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DunningLevelRemovalAdvicesDto
 */
export interface DunningLevelRemovalAdvicesDto {
    /**
     * A list of reasons that blocks the deletion, that can be a reference to a RuleSet 'RULE_SET' or unprocessed outcome that will use the dunning level 'UNPROCESSED_EXECUTION_OUTCOME'
     * @type {Array<string>}
     * @memberof DunningLevelRemovalAdvicesDto
     */
    deletionBlockers?: Array<DunningLevelRemovalAdvicesDtoDeletionBlockersEnum>;
    /**
     * A list of non blocking warnings in regard to deleting the dunning level, such as a receivable being in the given dunning level 'RECEIVABLE_STATES'
     * @type {Array<string>}
     * @memberof DunningLevelRemovalAdvicesDto
     */
    deletionNonBlockers?: Array<DunningLevelRemovalAdvicesDtoDeletionNonBlockersEnum>;
}


/**
 * @export
 */
export const DunningLevelRemovalAdvicesDtoDeletionBlockersEnum = {
    ReceivableStates: 'RECEIVABLE_STATES',
    RuleSet: 'RULE_SET',
    UnprocessedExecutionOutcome: 'UNPROCESSED_EXECUTION_OUTCOME'
} as const;
export type DunningLevelRemovalAdvicesDtoDeletionBlockersEnum = typeof DunningLevelRemovalAdvicesDtoDeletionBlockersEnum[keyof typeof DunningLevelRemovalAdvicesDtoDeletionBlockersEnum];

/**
 * @export
 */
export const DunningLevelRemovalAdvicesDtoDeletionNonBlockersEnum = {
    ReceivableStates: 'RECEIVABLE_STATES',
    RuleSet: 'RULE_SET',
    UnprocessedExecutionOutcome: 'UNPROCESSED_EXECUTION_OUTCOME'
} as const;
export type DunningLevelRemovalAdvicesDtoDeletionNonBlockersEnum = typeof DunningLevelRemovalAdvicesDtoDeletionNonBlockersEnum[keyof typeof DunningLevelRemovalAdvicesDtoDeletionNonBlockersEnum];


/**
 * Check if a given object implements the DunningLevelRemovalAdvicesDto interface.
 */
export function instanceOfDunningLevelRemovalAdvicesDto(value: object): value is DunningLevelRemovalAdvicesDto {
    return true;
}

export function DunningLevelRemovalAdvicesDtoFromJSON(json: any): DunningLevelRemovalAdvicesDto {
    return DunningLevelRemovalAdvicesDtoFromJSONTyped(json, false);
}

export function DunningLevelRemovalAdvicesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DunningLevelRemovalAdvicesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'deletionBlockers': json['deletionBlockers'] == null ? undefined : json['deletionBlockers'],
        'deletionNonBlockers': json['deletionNonBlockers'] == null ? undefined : json['deletionNonBlockers'],
    };
}

export function DunningLevelRemovalAdvicesDtoToJSON(value?: DunningLevelRemovalAdvicesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deletionBlockers': value['deletionBlockers'],
        'deletionNonBlockers': value['deletionNonBlockers'],
    };
}

