import { LICENSE_MODEL } from '../../interfaces/enums';
import { type AppLicense, LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../license/AppLicense';

export const licenseOptionDSL: AppLicense = {
  name: LICENSE_MODEL.LIGHT,
  featurePacks: [
    {
      name: LICENSE_FEATURE_PACK.RULESET_PACK,
      features: [
        {
          name: LICENSE_FEATURE.IMPORT_RULESET,
          enabled: false,
        },
        {
          name: LICENSE_FEATURE.EXPORT_RULESET,
          enabled: false,
        },
        {
          name: LICENSE_FEATURE.COPY_RULESET,
          enabled: false,
        },
        {
          name: LICENSE_FEATURE.RULESET_PARAMETERS,
          enabled: false,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.RULE_PACK,
      features: [
        {
          name: LICENSE_FEATURE.COPY_RULE,
          enabled: false,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.DUNNING_LEVEL_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CHANGE_OF_LEVEL_DESC_ORDER,
          enabled: false,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.CONTRACT_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.TERMINATION,
          enabled: false,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.ACTIONS_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CHANGE_OF_STATUS,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.NOTIFICATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION_THREAT,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION_ANNOUNCEMENT,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CONTRACT_TERMINATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DEBT_COLLECTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.FEE,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.CUSTOMER_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.WORKING_DAYS_SINCE_LAST_EXECUTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.WORKING_DAYS_SINCE_LAST_NOTIFICATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CALENDAR_DAYS_SINCE_LAST_EXECUTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CALENDAR_DAYS_SINCE_LAST_NOTIFICATION,
          enabled: true,
        },
      ],
    },
  ],
};

export const licenseOptionDSU: AppLicense = {
  name: LICENSE_MODEL.UNLIMITED,
  featurePacks: [
    {
      name: LICENSE_FEATURE_PACK.RULESET_PACK,
      features: [
        {
          name: LICENSE_FEATURE.IMPORT_RULESET,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.EXPORT_RULESET,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.RULESET_PARAMETERS,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.COPY_RULESET,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.RULE_PACK,
      features: [
        {
          name: LICENSE_FEATURE.COPY_RULE,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.DUNNING_LEVEL_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CHANGE_OF_LEVEL_DESC_ORDER,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.CONTRACT_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.TERMINATION,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.DYNAMIC_CONTRACT_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CREDIT_SCORE,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.PRODUCT_CODE,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.FORECAST_ANNUAL_CONSUMPTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.ADDITIONAL_VALUES,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.UN_ASSIGNED_PAYMENTS_AMOUNT,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CALENDAR_DAYS_UNTIL_CONTRACT_ENDS,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.WORKING_DAYS_UNTIL_CONTRACT_ENDS,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CONTRACT_TYPE,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.ACTIONS_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CHANGE_OF_STATUS,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.NOTIFICATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION_THREAT,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION_ANNOUNCEMENT,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DISCONNECTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CONTRACT_TERMINATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.DEBT_COLLECTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.FEE,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.CUSTOMER_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.WORKING_DAYS_SINCE_LAST_EXECUTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.WORKING_DAYS_SINCE_LAST_NOTIFICATION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CALENDAR_DAYS_SINCE_LAST_EXECUTION,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CALENDAR_DAYS_SINCE_LAST_NOTIFICATION,
          enabled: true,
        },
      ],
    },
    {
      name: LICENSE_FEATURE_PACK.DYNAMIC_CUSTOMER_SELECTION_PACK,
      features: [
        {
          name: LICENSE_FEATURE.CUSTOMER_TYPE,
          enabled: true,
        },
        {
          name: LICENSE_FEATURE.CUSTOMER_GROUP,
          enabled: true,
        },
      ],
    },
  ],
};
