/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DunningSelectionConfigurationDto,
  ErrorResponse,
} from '../models/index';
import {
    DunningSelectionConfigurationDtoFromJSON,
    DunningSelectionConfigurationDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface UpdateDunningSelectionRequest {
    dunningSelectionConfigurationDto: DunningSelectionConfigurationDto;
}

/**
 * 
 */
export class DunningSelectionApi extends runtime.BaseAPI {

    /**
     * Get dunning selection configuration
     * Get Dunning Selection
     */
    async getDunningSelectionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningSelectionConfigurationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dunning/selection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningSelectionConfigurationDtoFromJSON(jsonValue));
    }

    /**
     * Get dunning selection configuration
     * Get Dunning Selection
     */
    async getDunningSelection(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningSelectionConfigurationDto> {
        const response = await this.getDunningSelectionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update configuration for dunning selection
     * Update Dunning Selection
     */
    async updateDunningSelectionRaw(requestParameters: UpdateDunningSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningSelectionConfigurationDto>> {
        if (requestParameters['dunningSelectionConfigurationDto'] == null) {
            throw new runtime.RequiredError(
                'dunningSelectionConfigurationDto',
                'Required parameter "dunningSelectionConfigurationDto" was null or undefined when calling updateDunningSelection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dunning/selection`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DunningSelectionConfigurationDtoToJSON(requestParameters['dunningSelectionConfigurationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningSelectionConfigurationDtoFromJSON(jsonValue));
    }

    /**
     * Update configuration for dunning selection
     * Update Dunning Selection
     */
    async updateDunningSelection(requestParameters: UpdateDunningSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningSelectionConfigurationDto> {
        const response = await this.updateDunningSelectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
