/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    detail?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    traceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    errorType?: ErrorResponseErrorTypeEnum;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof ErrorResponse
     */
    additionalInformation?: Array<{ [key: string]: string; }>;
}


/**
 * @export
 */
export const ErrorResponseErrorTypeEnum = {
    ClientError: 'CLIENT_ERROR',
    ServerError: 'SERVER_ERROR'
} as const;
export type ErrorResponseErrorTypeEnum = typeof ErrorResponseErrorTypeEnum[keyof typeof ErrorResponseErrorTypeEnum];


/**
 * Check if a given object implements the ErrorResponse interface.
 */
export function instanceOfErrorResponse(value: object): value is ErrorResponse {
    return true;
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'detail': json['detail'] == null ? undefined : json['detail'],
        'status': json['status'] == null ? undefined : json['status'],
        'title': json['title'] == null ? undefined : json['title'],
        'traceId': json['traceId'] == null ? undefined : json['traceId'],
        'errorType': json['errorType'] == null ? undefined : json['errorType'],
        'additionalInformation': json['additionalInformation'] == null ? undefined : json['additionalInformation'],
    };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'detail': value['detail'],
        'status': value['status'],
        'title': value['title'],
        'traceId': value['traceId'],
        'errorType': value['errorType'],
        'additionalInformation': value['additionalInformation'],
    };
}

