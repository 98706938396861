/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  RuleSetAssignmentCriteriaCreateDto,
  RuleSetAssignmentCriteriaDto,
  RuleSetAssignmentCriteriaUpdateDto,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RuleSetAssignmentCriteriaCreateDtoFromJSON,
    RuleSetAssignmentCriteriaCreateDtoToJSON,
    RuleSetAssignmentCriteriaDtoFromJSON,
    RuleSetAssignmentCriteriaDtoToJSON,
    RuleSetAssignmentCriteriaUpdateDtoFromJSON,
    RuleSetAssignmentCriteriaUpdateDtoToJSON,
} from '../models/index';

export interface Create1Request {
    rulesetId: string;
    ruleSetAssignmentCriteriaCreateDto: RuleSetAssignmentCriteriaCreateDto;
}

export interface Delete1Request {
    rulesetId: string;
    id: string;
}

export interface GetByRulesetIdRequest {
    rulesetId: string;
}

export interface GetRuleSetAssignmentCriteriaByRulesetIdAndIdRequest {
    rulesetId: string;
    id: string;
}

export interface UpdateRequest {
    rulesetId: string;
    id: string;
    ruleSetAssignmentCriteriaUpdateDto: RuleSetAssignmentCriteriaUpdateDto;
}

/**
 * 
 */
export class RulesetAssignmentCriteriaApi extends runtime.BaseAPI {

    /**
     * Create a new ruleset assignment criteria
     * Create ruleset assignment criteria
     */
    async create1Raw(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetAssignmentCriteriaDto>> {
        if (requestParameters['rulesetId'] == null) {
            throw new runtime.RequiredError(
                'rulesetId',
                'Required parameter "rulesetId" was null or undefined when calling create1().'
            );
        }

        if (requestParameters['ruleSetAssignmentCriteriaCreateDto'] == null) {
            throw new runtime.RequiredError(
                'ruleSetAssignmentCriteriaCreateDto',
                'Required parameter "ruleSetAssignmentCriteriaCreateDto" was null or undefined when calling create1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/assignment-criteria`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters['rulesetId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetAssignmentCriteriaCreateDtoToJSON(requestParameters['ruleSetAssignmentCriteriaCreateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetAssignmentCriteriaDtoFromJSON(jsonValue));
    }

    /**
     * Create a new ruleset assignment criteria
     * Create ruleset assignment criteria
     */
    async create1(requestParameters: Create1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetAssignmentCriteriaDto> {
        const response = await this.create1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a ruleset assignment criteria
     * Delete a ruleset assignment criteria
     */
    async delete1Raw(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['rulesetId'] == null) {
            throw new runtime.RequiredError(
                'rulesetId',
                'Required parameter "rulesetId" was null or undefined when calling delete1().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling delete1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/assignment-criteria/{id}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters['rulesetId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ruleset assignment criteria
     * Delete a ruleset assignment criteria
     */
    async delete1(requestParameters: Delete1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delete1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async getByRulesetIdRaw(requestParameters: GetByRulesetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetAssignmentCriteriaDto>> {
        if (requestParameters['rulesetId'] == null) {
            throw new runtime.RequiredError(
                'rulesetId',
                'Required parameter "rulesetId" was null or undefined when calling getByRulesetId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/assignment-criteria`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters['rulesetId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetAssignmentCriteriaDtoFromJSON(jsonValue));
    }

    /**
     */
    async getByRulesetId(requestParameters: GetByRulesetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetAssignmentCriteriaDto> {
        const response = await this.getByRulesetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get existing ruleset assignment criteria
     * Get ruleset assignment criteria by ruleset ID and ID
     */
    async getRuleSetAssignmentCriteriaByRulesetIdAndIdRaw(requestParameters: GetRuleSetAssignmentCriteriaByRulesetIdAndIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetAssignmentCriteriaDto>> {
        if (requestParameters['rulesetId'] == null) {
            throw new runtime.RequiredError(
                'rulesetId',
                'Required parameter "rulesetId" was null or undefined when calling getRuleSetAssignmentCriteriaByRulesetIdAndId().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getRuleSetAssignmentCriteriaByRulesetIdAndId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset/{rulesetId}/assignment-criteria/{id}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters['rulesetId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetAssignmentCriteriaDtoFromJSON(jsonValue));
    }

    /**
     * Get existing ruleset assignment criteria
     * Get ruleset assignment criteria by ruleset ID and ID
     */
    async getRuleSetAssignmentCriteriaByRulesetIdAndId(requestParameters: GetRuleSetAssignmentCriteriaByRulesetIdAndIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetAssignmentCriteriaDto> {
        const response = await this.getRuleSetAssignmentCriteriaByRulesetIdAndIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update existing ruleset assignment criteria
     * Update ruleset assignment criteria
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetAssignmentCriteriaDto>> {
        if (requestParameters['rulesetId'] == null) {
            throw new runtime.RequiredError(
                'rulesetId',
                'Required parameter "rulesetId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling update().'
            );
        }

        if (requestParameters['ruleSetAssignmentCriteriaUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'ruleSetAssignmentCriteriaUpdateDto',
                'Required parameter "ruleSetAssignmentCriteriaUpdateDto" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset/{rulesetId}/assignment-criteria/{id}`.replace(`{${"rulesetId"}}`, encodeURIComponent(String(requestParameters['rulesetId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetAssignmentCriteriaUpdateDtoToJSON(requestParameters['ruleSetAssignmentCriteriaUpdateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetAssignmentCriteriaDtoFromJSON(jsonValue));
    }

    /**
     * Update existing ruleset assignment criteria
     * Update ruleset assignment criteria
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetAssignmentCriteriaDto> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
