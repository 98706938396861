/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CaseConditionDto } from './CaseConditionDto';
import {
    CaseConditionDtoFromJSON,
    CaseConditionDtoFromJSONTyped,
    CaseConditionDtoToJSON,
} from './CaseConditionDto';
import type { CustomerCaseConditionDto } from './CustomerCaseConditionDto';
import {
    CustomerCaseConditionDtoFromJSON,
    CustomerCaseConditionDtoFromJSONTyped,
    CustomerCaseConditionDtoToJSON,
} from './CustomerCaseConditionDto';

/**
 * 
 * @export
 * @interface RuleSetAssignmentCriteriaUpdateDto
 */
export interface RuleSetAssignmentCriteriaUpdateDto {
    /**
     * 
     * @type {Array<CaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaUpdateDto
     */
    caseConditions: Array<CaseConditionDto>;
    /**
     * 
     * @type {Array<CustomerCaseConditionDto>}
     * @memberof RuleSetAssignmentCriteriaUpdateDto
     */
    customerCaseConditions: Array<CustomerCaseConditionDto>;
}

/**
 * Check if a given object implements the RuleSetAssignmentCriteriaUpdateDto interface.
 */
export function instanceOfRuleSetAssignmentCriteriaUpdateDto(value: object): value is RuleSetAssignmentCriteriaUpdateDto {
    if (!('caseConditions' in value) || value['caseConditions'] === undefined) return false;
    if (!('customerCaseConditions' in value) || value['customerCaseConditions'] === undefined) return false;
    return true;
}

export function RuleSetAssignmentCriteriaUpdateDtoFromJSON(json: any): RuleSetAssignmentCriteriaUpdateDto {
    return RuleSetAssignmentCriteriaUpdateDtoFromJSONTyped(json, false);
}

export function RuleSetAssignmentCriteriaUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetAssignmentCriteriaUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'caseConditions': ((json['caseConditions'] as Array<any>).map(CaseConditionDtoFromJSON)),
        'customerCaseConditions': ((json['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoFromJSON)),
    };
}

export function RuleSetAssignmentCriteriaUpdateDtoToJSON(value?: RuleSetAssignmentCriteriaUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'caseConditions': ((value['caseConditions'] as Array<any>).map(CaseConditionDtoToJSON)),
        'customerCaseConditions': ((value['customerCaseConditions'] as Array<any>).map(CustomerCaseConditionDtoToJSON)),
    };
}

