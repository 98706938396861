import { type FunctionComponent, useContext, useState } from 'react';

import { Callout, Classes, Collapse, Dialog, Icon } from '@blueprintjs/core';
import { ChevronDown, ChevronRight, Error } from '@blueprintjs/icons';

import { IfLicensed } from 'components/IfLicensed';
import i18n from 'i18n/i18n';
import { ERROR_CODE, STATE } from 'interfaces/enums';
import {
  ActionForReact,
  AggregConditionForReact,
  CaseConditionForReact,
  CustomerCaseConditionForReact,
  GroupSelectorForReact,
  RuleAdvice,
  RuleForReact,
  RuleSetForReact,
  RuleTemplate,
} from 'interfaces/Interfaces';
import { Button } from 'lib/Button';
import { checkRuleForAdvices, createRule, updateRule, updateRuleNameAndDescription } from 'services/ApiService';
import { testAttribute, validateRuleObject, ValidationObject } from 'util/Util';

import RuleConditionGroup from './RuleDefinition/RuleCondition/RuleConditionGroup/RuleConditionGroup';
import RuleConditions from './RuleDefinition/RuleCondition/RuleConditions/RuleConditions';
import { actionDefinitionFactory } from './RuleDefinition/RuleConditionDefinitions/ActionInputDefinitions';
import { aggregatedRuleConditionDefinitionFactory } from './RuleDefinition/RuleConditionDefinitions/AggregatedConditionDefinitions';
import { caseRuleConditionDefinitionFactory } from './RuleDefinition/RuleConditionDefinitions/CaseConditionDefinitions';
import { customerRuleConditionDefinitionFactory } from './RuleDefinition/RuleConditionDefinitions/CustomerConditionDefinitions';
import { itemRuleConditionDefinitionFactory } from './RuleDefinition/RuleConditionDefinitions/ItemConditionDefinitions';
import RuleInformation from './RuleInformation/RuleInformation';
import { validateChangeLevelDescOrder } from './Validation/Validators/RuleDescDunningLevelValidator';
import { validateConditionIfTerminationAction } from './Validation/Validators/RuleMissingConditionIfTerminationValidator';
import { validateRuleNameIsSet } from './Validation/Validators/RuleNameIsSetValidator';
import { RuleAdviceDialog } from './RuleAdviceDialog';
import { RuleValidationView } from './RuleValidationView';
import { LicenseContext } from '../../../App';
import { ErrorResponse, ErrorResponseFromJSON, ResponseError } from '../../../generated-sources/openapi';
import {
  useActionsForLicense,
  useAttributesForLicense,
  useCaseAttributesForLicense,
  useItemAttributesForAggregatedConditionAndLicense,
  useItemAttributesForGroupSelectorAndLicense,
} from '../../../hooks/LicenseHooks';
import { Checkbox } from '../../../lib/Checkbox';
import { LICENSE_FEATURE, LICENSE_FEATURE_PACK } from '../../../license/AppLicense';
import { ValidationResult } from '../../DunningSelectionPage/DunningSelection/input/Validators';

import '../Dialog.style.sass';

interface RuleDialogProps {
  ruleSet: RuleSetForReact;
  rule?: RuleForReact;
  title: string;
  isNameAndDescriptionEditable: boolean;
  isRuleEditorEditable: boolean;
  buttonTextSuccess: string;
  buttonTextClose?: string;
  onCloseDialog: () => void;
  onConfirmRuleChange: (ruleset: RuleSetForReact, ruleId: string) => void;
}

const RuleDialog: FunctionComponent<RuleDialogProps> = (props: RuleDialogProps) => {
  const [validations, setValidations] = useState<ValidationObject>();
  const [ruleEdit, setRuleEdit] = useState<RuleTemplate>(new RuleTemplate(props.rule));
  const [optionalCollapseOpen, setOptionalCollapseOpen] = useState<boolean>(!props.isRuleEditorEditable || false);

  const [isSuccessButtonDisabled, setIsSuccessButtonDisabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isEditable, setIsEditable] = useState<boolean>(props.isRuleEditorEditable);
  const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false);

  const [showRuleAdviceDialog, setShowRuleAdviceDialog] = useState<boolean>(false);
  const [isSaveAdviceDialog, setIsSaveAdviceDialog] = useState<boolean>(true);
  const [ruleAdvices, setRuleAdvices] = useState<RuleAdvice[]>([]);
  const license = useContext(LicenseContext);

  function getOrderValue() {
    const maxOrder = Math.max(...props.ruleSet.rules.map(rule => rule.orderValue));
    return maxOrder > 0 ? maxOrder + 1 : 1;
  }

  const orderValue = props.rule?.orderValue ?? getOrderValue();
  const errorCodeMessagePair = getErrorCodeMessagePair();

  async function handleRuleError(errorMessage: ResponseError) {
    const ruleError: string = await errorMessage.response.json();
    const errorResponse = ErrorResponseFromJSON(ruleError);

    if (errorCodeMessagePair.has(errorResponse.title)) {
      setErrorMessage(`${i18n.t(errorCodeMessagePair.get(errorResponse.title))} (Code: ${errorResponse.title}).`);
    } else if (errorResponse.title) {
      setErrorMessage(`${i18n.t('error.rule-editor.error')} (Code: ${errorResponse.title}).`);
    } else {
      setErrorMessage(i18n.t('error.rule-editor.error'));
    }
  }

  function getErrorCodeMessagePair() {
    const errorErrorCodeMessageMap = new Map();
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NAME_IN_USE, 'error.rule-editor.name-double');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_NO_CHANGE_LEVEL_ACTION_RESTRICT, 'error.rule-editor.no-change-dunning-level-action-restrict');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_LEVEL_DESC_ORDER, 'dialog.rule.level-desc-order');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_MISSING_CONTRACT_TERMINATION_CONDITION, 'error.rule-editor.missing-contract-termination-cond');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_CONTRACT_ALREADY_TERMINATED, 'error.rule-editor.contract-already-terminated-cond');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_NO_SETTLEMENT_DAY_TYPE_SELECTED, 'error.rule-editor.settlement_day_type');
    errorErrorCodeMessageMap.set(ERROR_CODE.RULE_NOT_VALID_DUNNING_LEVEL_NOT_IN_RULESET, 'error.rule-editor.dunning-level-not-in-ruleset');
    return errorErrorCodeMessageMap;
  }

  function exchangeRuleFromRulesetWithNewRule(newRule: RuleForReact, ruleset: RuleSetForReact): RuleSetForReact {
    const newRules = ruleset.rules.map(oldRule => {
      return oldRule.id === newRule?.id ? newRule : oldRule;
    });
    const newRuleset = ruleset;
    newRuleset.rules = newRules;
    return newRuleset;
  }

  function getRuleValidations(): Array<(ruleEdit: RuleTemplate, ruleSet: RuleSetForReact) => ValidationResult | null> {
    const validators = [];
    if (
      !license.isFeatureEnabled(LICENSE_FEATURE_PACK.DUNNING_LEVEL_PACK, LICENSE_FEATURE.CHANGE_OF_LEVEL_DESC_ORDER)
    ) {
      validators.push(validateChangeLevelDescOrder);
    }
    if (license.isFeatureEnabled(LICENSE_FEATURE_PACK.CONTRACT_SELECTION_PACK, LICENSE_FEATURE.TERMINATION)) {
      validators.push(validateConditionIfTerminationAction);
    }
    validators.push(validateRuleNameIsSet);
    return validators;
  }

  function validate(): boolean {
    const ruleValidations: ValidationResult[] = getRuleValidations()
      .map(validator => validator(ruleEdit, props.ruleSet))
      .filter(validation => !!validation) as ValidationResult[];
    const validationWithRuleValidations: ValidationObject = { ...validations, rule: { global: { ruleValidations } } };
    setValidations(validationWithRuleValidations);
    return validateRuleObject(validationWithRuleValidations);
  }

  const onConditionChangeHandlerFactory =
    (setter: (data: any, object: any) => void) =>
    (newConditions: any, validations: Record<string, Record<string, ValidationResult[] | undefined>>) => {
      setRuleEdit(ruleEdit => {
        const copy = { ...ruleEdit };
        setter(newConditions, copy);
        return copy;
      });
      setValidations(old => {
        const copy = { ...old };
        setter(validations, copy);
        return copy;
      });
    };

  const onCustomerCaseConditionsChange = onConditionChangeHandlerFactory(
    (data, object) => (object.customerCaseConditions = data),
  );
  const onCaseConditionsChange = onConditionChangeHandlerFactory((data, object) => (object.caseConditions = data));
  const onGroupSelectorsChange = onConditionChangeHandlerFactory((data, object) => (object.groupSelectors = data));
  const onAggregConditionsInThisChange = onConditionChangeHandlerFactory(
    (data, object) => (object.aggregConditionsInThisGroup = data),
  );
  const onAggregConditionsInAllChange = onConditionChangeHandlerFactory(
    (data, object) => (object.aggregConditionsInAllGroups = data),
  );
  const onActionChange = onConditionChangeHandlerFactory((data, object) => (object.actions = data));

  const onNameChange = onConditionChangeHandlerFactory((data, object) => (object.name = data));
  const onDescriptionChange = onConditionChangeHandlerFactory((data, object) => (object.description = data));

  function handleSubmit() {
    setTriedToSubmit(true);
    if (!validate()) return;
    setIsSuccessButtonDisabled(true);

    // Update rule before activating
    if (props.rule && isEditable && props.ruleSet.state === STATE.EDIT) {
      checkForRuleAdvices().then(() => updateRuleForReact());
      return;
    }

    // Update Rule after activating
    if (props.rule && props.ruleSet.state !== STATE.EDIT) {
      updateRuleInformation();
      return;
    }

    // Create new Rule
    if (!props.rule) {
      checkForRuleAdvices().then(() => addRule());
    }
  }

  /** Checks for rule advices and only resolves, once the check is successfull */
  function checkForRuleAdvices() {
    return new Promise<void>(async resolve => {
      try {
        const advices = await checkRuleForAdvices(props.ruleSet.rulesetId, orderValue, ruleEdit);
        if (advices.length) {
          setShowRuleAdviceDialog(true);
          setRuleAdvices(advices);
          setIsSaveAdviceDialog(!!props.rule); // Is this when its in edit mode?!
        } else {
          resolve();
        }
      } catch (e: any) {
        await handleRuleError(e);
      } finally {
        setIsSuccessButtonDisabled(false);
      }
    });
  }

  function updateRuleForReact() {
    if (props.rule) {
      const ruleForReact: RuleForReact = {
        ...ruleEdit,
        id: props.rule.id,
        rulesetId: props.ruleSet.rulesetId,
        orderValue,
        created: props.rule.created,
        updated: props.rule.updated,
      };
      updateRule(ruleForReact)
        .then(rule => {
          props.onConfirmRuleChange(exchangeRuleFromRulesetWithNewRule(rule, props.ruleSet), rule.id);
        })
        .catch(error => handleRuleError(error))
        .finally(() => setIsSuccessButtonDisabled(false));
    }
  }

  function addRule() {
    createRule(props.ruleSet.rulesetId, orderValue, ruleEdit)
      .then(rule => {
        props.ruleSet.rules.push(rule);
        props.onConfirmRuleChange(props.ruleSet, rule.id);
      })

      .catch(error => handleRuleError(error))
      .finally(() => setIsSuccessButtonDisabled(false));
  }

  function updateRuleInformation() {
    if (!props.rule) return;
    updateRuleNameAndDescription(props.ruleSet.rulesetId, props.rule.id, ruleEdit.name, ruleEdit.description)
      .then(rule => {
        props.onConfirmRuleChange(exchangeRuleFromRulesetWithNewRule(rule, props.ruleSet), rule.id);
      })
      .catch(error => handleRuleError(error))
      .finally(() => setIsSuccessButtonDisabled(false));
  }

  return (
    <Dialog
      data-testid="add-rule-dialog"
      className="dialog large"
      isOpen
      canOutsideClickClose={false}
      onClose={() => props.onCloseDialog()}>
      <div className="dialog-title">{props.title}</div>
      <div className={Classes.DIALOG_BODY}>
        {/* **************************************************** */}
        {/* *****************  Error Message ******************* */}
        {/* **************************************************** */}
        {(triedToSubmit || errorMessage) && (
          <RuleValidationView errorMessage={errorMessage} validations={validations} />
        )}
        {/* **************************************************** */}
        {/* *****************  Advices Dialog ******************* */}
        {/* **************************************************** */}
        <RuleAdviceDialog
          advices={ruleAdvices}
          title={i18n.t('dialog.rule.advices')}
          open={showRuleAdviceDialog}
          buttonTextSuccess={props.buttonTextSuccess}
          onCloseDialog={() => setShowRuleAdviceDialog(false)}
          onConfirm={() => {
            setShowRuleAdviceDialog(false);
            isSaveAdviceDialog ? addRule() : updateRuleForReact();
          }}
        />
        {/* **************************************************** */}
        {/** *************  Name and description ***************** */}
        {/* **************************************************** */}
        <RuleInformation
          name={ruleEdit.name}
          description={ruleEdit.description}
          onDescriptionChange={onDescriptionChange}
          onNameChange={onNameChange}
          isEditable={isEditable}
        />
        <Checkbox
          label={i18n.t('dialog.rule.execute-contracts-separately')}
          isChecked={ruleEdit.executePerContract ?? false}
          disabled={!isEditable}
          onClick={() =>
            setRuleEdit(r => ({
              ...r,
              executePerContract: !ruleEdit.executePerContract,
            }))
          }
        />
        {/* **************************************************** */}
        {/** ******************  Blue callout ******************** */}
        {/* **************************************************** */}
        {isEditable && props.ruleSet.state === STATE.ACTIVE && (
          <Callout intent="primary" icon={<Error />}>
            {i18n.t('dialog.rule.rule-edit-active-callout')}
          </Callout>
        )}

        {/* **************************************************** */}
        {/** *****************  Rule definition ****************** */}
        {/* **************************************************** */}
        <div className="dialog-title-2 required-field">{i18n.t('dialog.rule.when')}</div>
        {isEditable && <div>{i18n.t('dialog.rule.when-text')}</div>}
        <RuleConditionGroup title={i18n.t('dialog.rule.when-customer-case-title')}>
          <RuleConditions
            conditionTypes={useAttributesForLicense(
              LICENSE_FEATURE_PACK.CUSTOMER_SELECTION_PACK,
              'CUSTOMER_ATTRIBUTE',
            ).map(type => ({
              type,
              name: i18n.t(`select.${type}`),
            }))}
            conditions={ruleEdit.customerCaseConditions}
            availableParameters={props.ruleSet.parameters}
            definitions={customerRuleConditionDefinitionFactory()}
            isEditable={props.ruleSet.state === STATE.EDIT}
            newConditionFactory={() => new CustomerCaseConditionForReact()}
            onChange={onCustomerCaseConditionsChange}
            typeGetter={object => object.attrib}
            typeSetter={(object, type) => (object.attrib = type)}
            section='customer'
          />
        </RuleConditionGroup>

        <RuleConditionGroup title={i18n.t('dialog.rule.when-case-title')}>
          <RuleConditions
            conditionTypes={useCaseAttributesForLicense().map(type => ({
              type,
              name: i18n.t(`select.${type}`),
            }))}
            conditions={ruleEdit.caseConditions}
            availableParameters={props.ruleSet.parameters}
            definitions={caseRuleConditionDefinitionFactory(props.ruleSet.dunningLevels)}
            isEditable={props.ruleSet.state === STATE.EDIT}
            newConditionFactory={() => new CaseConditionForReact()}
            onChange={onCaseConditionsChange}
            typeGetter={object => object.attrib}
            typeSetter={(object, type) => (object.attrib = type)}
            section='case'
          />
        </RuleConditionGroup>

        <RuleConditionGroup title={i18n.t('dialog.rule.when-aggreg-title')}>
          <div className="right-left-padding">{i18n.t('dialog.rule.selection')}</div>
          <RuleConditions
            conditionTypes={useItemAttributesForGroupSelectorAndLicense().map(type => ({
              type,
              name: i18n.t(`select.${type}`),
            }))}
            conditions={ruleEdit.groupSelectors}
            availableParameters={props.ruleSet.parameters}
            definitions={itemRuleConditionDefinitionFactory(props.ruleSet.dunningLevels)}
            isEditable={props.ruleSet.state === STATE.EDIT}
            newConditionFactory={() => new GroupSelectorForReact()}
            onChange={onGroupSelectorsChange}
            typeGetter={object => object.attrib}
            typeSetter={(object, type) => (object.attrib = type)}
            section='selection'
          />
          <div className="bottom-line padding-top" />
          <div className="padding-top right-left-padding">{i18n.t('dialog.rule.aggreg-title')}</div>
          <RuleConditions
            conditionTypes={useItemAttributesForAggregatedConditionAndLicense().map(type => ({
              type,
              name: i18n.t(`select.${type}`),
            }))}
            conditions={ruleEdit.aggregConditionsInAllGroups}
            availableParameters={props.ruleSet.parameters}
            definitions={aggregatedRuleConditionDefinitionFactory(props.ruleSet.dunningLevels)}
            isEditable={props.ruleSet.state === STATE.EDIT}
            newConditionFactory={() => new AggregConditionForReact()}
            onChange={onAggregConditionsInAllChange}
            typeGetter={object => object.attrib}
            typeSetter={(object, type) => (object.attrib = type)}
            section='aggreg'
          />
        </RuleConditionGroup>

        <IfLicensed unlimited>
          <div className="padding-top pointer" onClick={() => setOptionalCollapseOpen(!optionalCollapseOpen)}>
            <Icon icon={optionalCollapseOpen ? <ChevronDown /> : <ChevronRight />} />
            {i18n.t('dialog.rule.additional-options')}
          </div>
          <Collapse isOpen={optionalCollapseOpen}>
            <div className="form-group">
              <RuleConditionGroup title={i18n.t('dialog.rule.aggreg-title-all')}>
                <RuleConditions
                  conditionTypes={useItemAttributesForAggregatedConditionAndLicense().map(type => ({
                    type,
                    name: i18n.t(`select.${type}`),
                  }))}
                  conditions={ruleEdit.aggregConditionsInThisGroup}
                  availableParameters={props.ruleSet.parameters}
                  definitions={aggregatedRuleConditionDefinitionFactory(props.ruleSet.dunningLevels)}
                  isEditable={props.ruleSet.state === STATE.EDIT}
                  newConditionFactory={() => new AggregConditionForReact()}
                  onChange={onAggregConditionsInThisChange}
                  typeGetter={object => object.attrib}
                  typeSetter={(object, type) => (object.attrib = type)}
                  section='aggreg-all'
                />
              </RuleConditionGroup>
            </div>
          </Collapse>
        </IfLicensed>
        <div className="dialog-title-2 required-field padding-top">{i18n.t('dialog.rule.then')}</div>
        {/* Action Definitions */}
        {isEditable && <div>{i18n.t('dialog.rule.then-text')}</div>}
        <div className="form-group">
          <RuleConditionGroup title={i18n.t('dialog.rule.action-title')}>
            <RuleConditions
              conditionTypes={useActionsForLicense().map(type => ({
                type,
                name: i18n.t(`select.${type}`),
              }))}
              conditions={ruleEdit.actions}
              availableParameters={props.ruleSet.parameters}
              definitions={actionDefinitionFactory(props.ruleSet.dunningLevels)}
              isEditable={props.ruleSet.state === STATE.EDIT}
              newConditionFactory={() => new ActionForReact()}
              onChange={onActionChange}
              typeSetter={(object, type) => (object['@type'] = type)}
              typeGetter={object => object['@type'] ?? ''}
              addTranslationKey="dialog.rule.add-action"
              section='action'
            />
          </RuleConditionGroup>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!isEditable && (
            <Button
              testId={testAttribute('tt1z', 'button.edit')}
              id="cancel-button"
              className="button left"
              onClick={() => setIsEditable(true)}>
              {i18n.t('button.edit')}
            </Button>
          )}
          <Button
            id="cancel-button"
            testId={testAttribute('da13', 'button.cancel')}
            className="button"
            onClick={() => props.onCloseDialog()}>
            {props.buttonTextClose ?? i18n.t('button.cancel')}
          </Button>
          {isEditable && (
            <Button
              testId={testAttribute('48a1', 'button.success')}
              id="save-button"
              className="button"
              type="primary"
              onClick={() => handleSubmit()}
              disabled={isSuccessButtonDisabled}>
              {props.buttonTextSuccess}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default RuleDialog;
