/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Feature } from './Feature';
import {
    FeatureFromJSON,
    FeatureFromJSONTyped,
    FeatureToJSON,
} from './Feature';

/**
 * Group of features (Feature Pack)
 * @export
 * @interface FeaturePack
 */
export interface FeaturePack {
    /**
     * Feature Pack name
     * @type {string}
     * @memberof FeaturePack
     */
    name?: string;
    /**
     * List of features
     * @type {Array<Feature>}
     * @memberof FeaturePack
     */
    features?: Array<Feature>;
}

/**
 * Check if a given object implements the FeaturePack interface.
 */
export function instanceOfFeaturePack(value: object): value is FeaturePack {
    return true;
}

export function FeaturePackFromJSON(json: any): FeaturePack {
    return FeaturePackFromJSONTyped(json, false);
}

export function FeaturePackFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturePack {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'features': json['features'] == null ? undefined : ((json['features'] as Array<any>).map(FeatureFromJSON)),
    };
}

export function FeaturePackToJSON(value?: FeaturePack | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'features': value['features'] == null ? undefined : ((value['features'] as Array<any>).map(FeatureToJSON)),
    };
}

