/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DunningLevelDto,
  DunningLevelRemovalAdvicesDto,
  DunningLevelSaveDto,
  ErrorResponse,
} from '../models/index';
import {
    DunningLevelDtoFromJSON,
    DunningLevelDtoToJSON,
    DunningLevelRemovalAdvicesDtoFromJSON,
    DunningLevelRemovalAdvicesDtoToJSON,
    DunningLevelSaveDtoFromJSON,
    DunningLevelSaveDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface CheckAdvicesToRemoveRequest {
    id: string;
}

export interface Create2Request {
    dunningLevelSaveDto: DunningLevelSaveDto;
}

export interface Delete2Request {
    id: string;
}

export interface GetDunningLevelByIdRequest {
    id: string;
}

export interface UpdateDunningLevelRequest {
    id: string;
    dunningLevelSaveDto: DunningLevelSaveDto;
}

/**
 * 
 */
export class DunningLevelApi extends runtime.BaseAPI {

    /**
     * Get removal advices
     * Removal Advices
     */
    async checkAdvicesToRemoveRaw(requestParameters: CheckAdvicesToRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningLevelRemovalAdvicesDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling checkAdvicesToRemove().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dunning-level/{id}/delete-advices`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningLevelRemovalAdvicesDtoFromJSON(jsonValue));
    }

    /**
     * Get removal advices
     * Removal Advices
     */
    async checkAdvicesToRemove(requestParameters: CheckAdvicesToRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningLevelRemovalAdvicesDto> {
        const response = await this.checkAdvicesToRemoveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a dunning level
     * Create
     */
    async create2Raw(requestParameters: Create2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningLevelDto>> {
        if (requestParameters['dunningLevelSaveDto'] == null) {
            throw new runtime.RequiredError(
                'dunningLevelSaveDto',
                'Required parameter "dunningLevelSaveDto" was null or undefined when calling create2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dunning-level`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DunningLevelSaveDtoToJSON(requestParameters['dunningLevelSaveDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningLevelDtoFromJSON(jsonValue));
    }

    /**
     * Create a dunning level
     * Create
     */
    async create2(requestParameters: Create2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningLevelDto> {
        const response = await this.create2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a dunning level
     * Dunning
     */
    async delete2Raw(requestParameters: Delete2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling delete2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dunning-level/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a dunning level
     * Dunning
     */
    async delete2(requestParameters: Delete2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.delete2Raw(requestParameters, initOverrides);
    }

    /**
     * Get dunning levels by id
     * Get by ID
     */
    async getDunningLevelByIdRaw(requestParameters: GetDunningLevelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningLevelDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDunningLevelById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dunning-level/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningLevelDtoFromJSON(jsonValue));
    }

    /**
     * Get dunning levels by id
     * Get by ID
     */
    async getDunningLevelById(requestParameters: GetDunningLevelByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningLevelDto> {
        const response = await this.getDunningLevelByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all available dunning levels
     * Get All
     */
    async getDunningLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DunningLevelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dunning-level`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DunningLevelDtoFromJSON));
    }

    /**
     * Get a list of all available dunning levels
     * Get All
     */
    async getDunningLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DunningLevelDto>> {
        const response = await this.getDunningLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a dunning level name and/or description
     * Update
     */
    async updateDunningLevelRaw(requestParameters: UpdateDunningLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DunningLevelDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDunningLevel().'
            );
        }

        if (requestParameters['dunningLevelSaveDto'] == null) {
            throw new runtime.RequiredError(
                'dunningLevelSaveDto',
                'Required parameter "dunningLevelSaveDto" was null or undefined when calling updateDunningLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/dunning-level/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DunningLevelSaveDtoToJSON(requestParameters['dunningLevelSaveDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DunningLevelDtoFromJSON(jsonValue));
    }

    /**
     * Update a dunning level name and/or description
     * Update
     */
    async updateDunningLevel(requestParameters: UpdateDunningLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DunningLevelDto> {
        const response = await this.updateDunningLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
