/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkExecutionStatusDto
 */
export interface BulkExecutionStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof BulkExecutionStatusDto
     */
    isRunning?: boolean;
}

/**
 * Check if a given object implements the BulkExecutionStatusDto interface.
 */
export function instanceOfBulkExecutionStatusDto(value: object): value is BulkExecutionStatusDto {
    return true;
}

export function BulkExecutionStatusDtoFromJSON(json: any): BulkExecutionStatusDto {
    return BulkExecutionStatusDtoFromJSONTyped(json, false);
}

export function BulkExecutionStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkExecutionStatusDto {
    if (json == null) {
        return json;
    }
    return {
        
        'isRunning': json['isRunning'] == null ? undefined : json['isRunning'],
    };
}

export function BulkExecutionStatusDtoToJSON(value?: BulkExecutionStatusDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isRunning': value['isRunning'],
    };
}

