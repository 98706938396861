/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemPropertyUpdateDto
 */
export interface SystemPropertyUpdateDto {
    /**
     * The property key to record identity
     * @type {string}
     * @memberof SystemPropertyUpdateDto
     */
    property?: string;
    /**
     * The value of the property
     * @type {string}
     * @memberof SystemPropertyUpdateDto
     */
    value?: string;
}

/**
 * Check if a given object implements the SystemPropertyUpdateDto interface.
 */
export function instanceOfSystemPropertyUpdateDto(value: object): value is SystemPropertyUpdateDto {
    return true;
}

export function SystemPropertyUpdateDtoFromJSON(json: any): SystemPropertyUpdateDto {
    return SystemPropertyUpdateDtoFromJSONTyped(json, false);
}

export function SystemPropertyUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemPropertyUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'property': json['property'] == null ? undefined : json['property'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function SystemPropertyUpdateDtoToJSON(value?: SystemPropertyUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'property': value['property'],
        'value': value['value'],
    };
}

