/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetIdWithTimeSliceDto
 */
export interface RuleSetIdWithTimeSliceDto {
    /**
     * 
     * @type {string}
     * @memberof RuleSetIdWithTimeSliceDto
     */
    ruleSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetIdWithTimeSliceDto
     */
    validFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleSetIdWithTimeSliceDto
     */
    validUntil?: Date;
}

/**
 * Check if a given object implements the RuleSetIdWithTimeSliceDto interface.
 */
export function instanceOfRuleSetIdWithTimeSliceDto(value: object): value is RuleSetIdWithTimeSliceDto {
    if (!('validFrom' in value) || value['validFrom'] === undefined) return false;
    return true;
}

export function RuleSetIdWithTimeSliceDtoFromJSON(json: any): RuleSetIdWithTimeSliceDto {
    return RuleSetIdWithTimeSliceDtoFromJSONTyped(json, false);
}

export function RuleSetIdWithTimeSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetIdWithTimeSliceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'ruleSetId': json['ruleSetId'] == null ? undefined : json['ruleSetId'],
        'validFrom': (new Date(json['validFrom'])),
        'validUntil': json['validUntil'] == null ? undefined : (new Date(json['validUntil'])),
    };
}

export function RuleSetIdWithTimeSliceDtoToJSON(value?: RuleSetIdWithTimeSliceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ruleSetId': value['ruleSetId'],
        'validFrom': ((value['validFrom']).toISOString()),
        'validUntil': value['validUntil'] == null ? undefined : ((value['validUntil']).toISOString()),
    };
}

