/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleDescriptionUpdateDto
 */
export interface RuleDescriptionUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RuleDescriptionUpdateDto
     */
    description?: string;
}

/**
 * Check if a given object implements the RuleDescriptionUpdateDto interface.
 */
export function instanceOfRuleDescriptionUpdateDto(value: object): value is RuleDescriptionUpdateDto {
    return true;
}

export function RuleDescriptionUpdateDtoFromJSON(json: any): RuleDescriptionUpdateDto {
    return RuleDescriptionUpdateDtoFromJSONTyped(json, false);
}

export function RuleDescriptionUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleDescriptionUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function RuleDescriptionUpdateDtoToJSON(value?: RuleDescriptionUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
    };
}

