/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExecutionOutcomeDto } from './ExecutionOutcomeDto';
import {
    ExecutionOutcomeDtoFromJSON,
    ExecutionOutcomeDtoFromJSONTyped,
    ExecutionOutcomeDtoToJSON,
} from './ExecutionOutcomeDto';

/**
 * 
 * @export
 * @interface ExecutionOverviewDto
 */
export interface ExecutionOverviewDto {
    /**
     * The id of the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    id?: string;
    /**
     * The id of the rule set using in the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    ruleSetId?: string;
    /**
     * The date and time of the execution
     * @type {Date}
     * @memberof ExecutionOverviewDto
     */
    timestamp?: Date;
    /**
     * The customer id associated with the contract
     * @type {number}
     * @memberof ExecutionOverviewDto
     */
    customerId?: number;
    /**
     * The ids of the contracts involved in the execution
     * @type {Array<number>}
     * @memberof ExecutionOverviewDto
     */
    contractIds?: Array<number>;
    /**
     * The name of the rule set using in the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    ruleSetName?: string;
    /**
     * The state of the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    executionState?: ExecutionOverviewDtoExecutionStateEnum;
    /**
     * 
     * @type {Array<ExecutionOutcomeDto>}
     * @memberof ExecutionOverviewDto
     */
    outcomes?: Array<ExecutionOutcomeDto>;
    /**
     * 
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    highestChangeOfLevel?: string;
    /**
     * Last time the execution was retried
     * @type {Date}
     * @memberof ExecutionOverviewDto
     */
    lastModified?: Date;
    /**
     * Last user who retried the execution
     * @type {string}
     * @memberof ExecutionOverviewDto
     */
    lastModifiedUser?: string;
}


/**
 * @export
 */
export const ExecutionOverviewDtoExecutionStateEnum = {
    Pending: 'PENDING',
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED',
    Retry: 'RETRY',
    Canceled: 'CANCELED'
} as const;
export type ExecutionOverviewDtoExecutionStateEnum = typeof ExecutionOverviewDtoExecutionStateEnum[keyof typeof ExecutionOverviewDtoExecutionStateEnum];


/**
 * Check if a given object implements the ExecutionOverviewDto interface.
 */
export function instanceOfExecutionOverviewDto(value: object): value is ExecutionOverviewDto {
    return true;
}

export function ExecutionOverviewDtoFromJSON(json: any): ExecutionOverviewDto {
    return ExecutionOverviewDtoFromJSONTyped(json, false);
}

export function ExecutionOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutionOverviewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'ruleSetId': json['ruleSetId'] == null ? undefined : json['ruleSetId'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
        'customerId': json['customerId'] == null ? undefined : json['customerId'],
        'contractIds': json['contractIds'] == null ? undefined : json['contractIds'],
        'ruleSetName': json['ruleSetName'] == null ? undefined : json['ruleSetName'],
        'executionState': json['executionState'] == null ? undefined : json['executionState'],
        'outcomes': json['outcomes'] == null ? undefined : ((json['outcomes'] as Array<any>).map(ExecutionOutcomeDtoFromJSON)),
        'highestChangeOfLevel': json['highestChangeOfLevel'] == null ? undefined : json['highestChangeOfLevel'],
        'lastModified': json['lastModified'] == null ? undefined : (new Date(json['lastModified'])),
        'lastModifiedUser': json['lastModifiedUser'] == null ? undefined : json['lastModifiedUser'],
    };
}

export function ExecutionOverviewDtoToJSON(value?: ExecutionOverviewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'ruleSetId': value['ruleSetId'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
        'customerId': value['customerId'],
        'contractIds': value['contractIds'],
        'ruleSetName': value['ruleSetName'],
        'executionState': value['executionState'],
        'outcomes': value['outcomes'] == null ? undefined : ((value['outcomes'] as Array<any>).map(ExecutionOutcomeDtoToJSON)),
        'highestChangeOfLevel': value['highestChangeOfLevel'],
        'lastModified': value['lastModified'] == null ? undefined : ((value['lastModified']).toISOString()),
        'lastModifiedUser': value['lastModifiedUser'],
    };
}

