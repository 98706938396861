/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RuleSetInfoDto } from './RuleSetInfoDto';
import {
    RuleSetInfoDtoFromJSON,
    RuleSetInfoDtoFromJSONTyped,
    RuleSetInfoDtoToJSON,
} from './RuleSetInfoDto';

/**
 * 
 * @export
 * @interface ContractRuleSetCustomizationResponseDto
 */
export interface ContractRuleSetCustomizationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    contractId?: number;
    /**
     * 
     * @type {RuleSetInfoDto}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    associatedRuleSet?: RuleSetInfoDto;
    /**
     * 
     * @type {Date}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContractRuleSetCustomizationResponseDto
     */
    validUntil?: Date;
}

/**
 * Check if a given object implements the ContractRuleSetCustomizationResponseDto interface.
 */
export function instanceOfContractRuleSetCustomizationResponseDto(value: object): value is ContractRuleSetCustomizationResponseDto {
    return true;
}

export function ContractRuleSetCustomizationResponseDtoFromJSON(json: any): ContractRuleSetCustomizationResponseDto {
    return ContractRuleSetCustomizationResponseDtoFromJSONTyped(json, false);
}

export function ContractRuleSetCustomizationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractRuleSetCustomizationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'contractId': json['contractId'] == null ? undefined : json['contractId'],
        'associatedRuleSet': json['associatedRuleSet'] == null ? undefined : RuleSetInfoDtoFromJSON(json['associatedRuleSet']),
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validUntil': json['validUntil'] == null ? undefined : (new Date(json['validUntil'])),
    };
}

export function ContractRuleSetCustomizationResponseDtoToJSON(value?: ContractRuleSetCustomizationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contractId': value['contractId'],
        'associatedRuleSet': RuleSetInfoDtoToJSON(value['associatedRuleSet']),
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString()),
        'validUntil': value['validUntil'] == null ? undefined : ((value['validUntil']).toISOString()),
    };
}

