/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DunningPageRequest
 */
export interface DunningPageRequest {
    /**
     * 
     * @type {number}
     * @memberof DunningPageRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof DunningPageRequest
     */
    pageSize?: number;
}

/**
 * Check if a given object implements the DunningPageRequest interface.
 */
export function instanceOfDunningPageRequest(value: object): value is DunningPageRequest {
    return true;
}

export function DunningPageRequestFromJSON(json: any): DunningPageRequest {
    return DunningPageRequestFromJSONTyped(json, false);
}

export function DunningPageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DunningPageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'] == null ? undefined : json['page'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
    };
}

export function DunningPageRequestToJSON(value?: DunningPageRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'pageSize': value['pageSize'],
    };
}

