/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleSetExecutionsSummaryDto
 */
export interface RuleSetExecutionsSummaryDto {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RuleSetExecutionsSummaryDto
     */
    summary?: { [key: string]: number; };
}

/**
 * Check if a given object implements the RuleSetExecutionsSummaryDto interface.
 */
export function instanceOfRuleSetExecutionsSummaryDto(value: object): value is RuleSetExecutionsSummaryDto {
    return true;
}

export function RuleSetExecutionsSummaryDtoFromJSON(json: any): RuleSetExecutionsSummaryDto {
    return RuleSetExecutionsSummaryDtoFromJSONTyped(json, false);
}

export function RuleSetExecutionsSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSetExecutionsSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'summary': json['summary'] == null ? undefined : json['summary'],
    };
}

export function RuleSetExecutionsSummaryDtoToJSON(value?: RuleSetExecutionsSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'summary': value['summary'],
    };
}

