/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractStateOverview
 */
export interface ContractStateOverview {
    /**
     * The customer id associated with the contract
     * @type {number}
     * @memberof ContractStateOverview
     */
    customerId?: number;
    /**
     * The id of the contract
     * @type {number}
     * @memberof ContractStateOverview
     */
    contractId?: number;
    /**
     * The dunning level of the contract, which is the maximum of the levels of the receivables
     * @type {string}
     * @memberof ContractStateOverview
     */
    dunningLevel?: string;
    /**
     * True, if the contract finally invoiced
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    isFinallyInvoiced?: boolean;
    /**
     * True, if the contract has customized ruleset assignment at the time of last execution
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    isCustomized?: boolean;
    /**
     * The state of the last execution
     * @type {string}
     * @memberof ContractStateOverview
     */
    lastExecutionState?: string;
    /**
     * The date and time of the last letter sent
     * @type {Date}
     * @memberof ContractStateOverview
     */
    lastLetterSent?: Date;
    /**
     * The date and time of the last execution
     * @type {Date}
     * @memberof ContractStateOverview
     */
    lastExecutionTime?: Date;
    /**
     * The name of the active ruleset associated with the contract
     * @type {string}
     * @memberof ContractStateOverview
     */
    activeRuleSetName?: string;
    /**
     * If the execution is skipped for the contract.
     * @type {boolean}
     * @memberof ContractStateOverview
     */
    skipExecution?: boolean;
    /**
     * Deadline of the lock ( aka. skipping execution ) 
     * @type {Date}
     * @memberof ContractStateOverview
     */
    skipExecutionUntil?: Date;
}

/**
 * Check if a given object implements the ContractStateOverview interface.
 */
export function instanceOfContractStateOverview(value: object): value is ContractStateOverview {
    return true;
}

export function ContractStateOverviewFromJSON(json: any): ContractStateOverview {
    return ContractStateOverviewFromJSONTyped(json, false);
}

export function ContractStateOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractStateOverview {
    if (json == null) {
        return json;
    }
    return {
        
        'customerId': json['customerId'] == null ? undefined : json['customerId'],
        'contractId': json['contractId'] == null ? undefined : json['contractId'],
        'dunningLevel': json['dunningLevel'] == null ? undefined : json['dunningLevel'],
        'isFinallyInvoiced': json['isFinallyInvoiced'] == null ? undefined : json['isFinallyInvoiced'],
        'isCustomized': json['isCustomized'] == null ? undefined : json['isCustomized'],
        'lastExecutionState': json['lastExecutionState'] == null ? undefined : json['lastExecutionState'],
        'lastLetterSent': json['lastLetterSent'] == null ? undefined : (new Date(json['lastLetterSent'])),
        'lastExecutionTime': json['lastExecutionTime'] == null ? undefined : (new Date(json['lastExecutionTime'])),
        'activeRuleSetName': json['activeRuleSetName'] == null ? undefined : json['activeRuleSetName'],
        'skipExecution': json['skipExecution'] == null ? undefined : json['skipExecution'],
        'skipExecutionUntil': json['skipExecutionUntil'] == null ? undefined : (new Date(json['skipExecutionUntil'])),
    };
}

export function ContractStateOverviewToJSON(value?: ContractStateOverview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerId': value['customerId'],
        'contractId': value['contractId'],
        'dunningLevel': value['dunningLevel'],
        'isFinallyInvoiced': value['isFinallyInvoiced'],
        'isCustomized': value['isCustomized'],
        'lastExecutionState': value['lastExecutionState'],
        'lastLetterSent': value['lastLetterSent'] == null ? undefined : ((value['lastLetterSent']).toISOString()),
        'lastExecutionTime': value['lastExecutionTime'] == null ? undefined : ((value['lastExecutionTime']).toISOString()),
        'activeRuleSetName': value['activeRuleSetName'],
        'skipExecution': value['skipExecution'],
        'skipExecutionUntil': value['skipExecutionUntil'] == null ? undefined : ((value['skipExecutionUntil']).toISOString()),
    };
}

