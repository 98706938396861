/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContractRuleSetCustomizationResponseDto,
  RuleSetCustomizationSyncDto,
  RuleSetCustomizationSyncResponseDto,
  RuleSetIdWithTimeSliceDto,
  RuleSetMigrationDto,
} from '../models/index';
import {
    ContractRuleSetCustomizationResponseDtoFromJSON,
    ContractRuleSetCustomizationResponseDtoToJSON,
    RuleSetCustomizationSyncDtoFromJSON,
    RuleSetCustomizationSyncDtoToJSON,
    RuleSetCustomizationSyncResponseDtoFromJSON,
    RuleSetCustomizationSyncResponseDtoToJSON,
    RuleSetIdWithTimeSliceDtoFromJSON,
    RuleSetIdWithTimeSliceDtoToJSON,
    RuleSetMigrationDtoFromJSON,
    RuleSetMigrationDtoToJSON,
} from '../models/index';

export interface GetActiveRuleSetCustomizationRequest {
    contractId: number;
}

export interface GetRuleSetIdsRequest {
    contractId: number;
}

export interface MigrateRequest {
    ruleSetMigrationDto: RuleSetMigrationDto;
}

export interface SearchContractRuleSetCustomizationsRequest {
    ruleSetIds?: Array<string>;
    contractIds?: Array<number>;
    validOn?: Date;
    state?: SearchContractRuleSetCustomizationsStateEnum;
}

export interface SetRuleSetId1Request {
    contractId: number;
    ruleSetIdWithTimeSliceDto: RuleSetIdWithTimeSliceDto;
}

export interface TriggerSyncRequest {
    ruleSetCustomizationSyncDto: RuleSetCustomizationSyncDto;
}

/**
 * 
 */
export class ContractRulesetCustomizationApi extends runtime.BaseAPI {

    /**
     */
    async getActiveRuleSetCustomizationRaw(requestParameters: GetActiveRuleSetCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContractRuleSetCustomizationResponseDto>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getActiveRuleSetCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['contractId'] != null) {
            queryParameters['contractId'] = requestParameters['contractId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset-customization/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractRuleSetCustomizationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getActiveRuleSetCustomization(requestParameters: GetActiveRuleSetCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContractRuleSetCustomizationResponseDto> {
        const response = await this.getActiveRuleSetCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRuleSetIdsRaw(requestParameters: GetRuleSetIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContractRuleSetCustomizationResponseDto>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getRuleSetIds().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset-customization/contract/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractRuleSetCustomizationResponseDtoFromJSON));
    }

    /**
     */
    async getRuleSetIds(requestParameters: GetRuleSetIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContractRuleSetCustomizationResponseDto>> {
        const response = await this.getRuleSetIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async migrateRaw(requestParameters: MigrateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['ruleSetMigrationDto'] == null) {
            throw new runtime.RequiredError(
                'ruleSetMigrationDto',
                'Required parameter "ruleSetMigrationDto" was null or undefined when calling migrate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset-customization/migrate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetMigrationDtoToJSON(requestParameters['ruleSetMigrationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async migrate(requestParameters: MigrateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.migrateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchContractRuleSetCustomizationsRaw(requestParameters: SearchContractRuleSetCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContractRuleSetCustomizationResponseDto>>> {
        const queryParameters: any = {};

        if (requestParameters['ruleSetIds'] != null) {
            queryParameters['ruleSetIds'] = requestParameters['ruleSetIds'];
        }

        if (requestParameters['contractIds'] != null) {
            queryParameters['contractIds'] = requestParameters['contractIds'];
        }

        if (requestParameters['validOn'] != null) {
            queryParameters['validOn'] = (requestParameters['validOn'] as any).toISOString();
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ruleset-customization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractRuleSetCustomizationResponseDtoFromJSON));
    }

    /**
     */
    async searchContractRuleSetCustomizations(requestParameters: SearchContractRuleSetCustomizationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContractRuleSetCustomizationResponseDto>> {
        const response = await this.searchContractRuleSetCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setRuleSetId1Raw(requestParameters: SetRuleSetId1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContractRuleSetCustomizationResponseDto>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setRuleSetId1().'
            );
        }

        if (requestParameters['ruleSetIdWithTimeSliceDto'] == null) {
            throw new runtime.RequiredError(
                'ruleSetIdWithTimeSliceDto',
                'Required parameter "ruleSetIdWithTimeSliceDto" was null or undefined when calling setRuleSetId1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset-customization/contract/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetIdWithTimeSliceDtoToJSON(requestParameters['ruleSetIdWithTimeSliceDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractRuleSetCustomizationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async setRuleSetId1(requestParameters: SetRuleSetId1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContractRuleSetCustomizationResponseDto> {
        const response = await this.setRuleSetId1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async triggerSyncRaw(requestParameters: TriggerSyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RuleSetCustomizationSyncResponseDto>> {
        if (requestParameters['ruleSetCustomizationSyncDto'] == null) {
            throw new runtime.RequiredError(
                'ruleSetCustomizationSyncDto',
                'Required parameter "ruleSetCustomizationSyncDto" was null or undefined when calling triggerSync().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ruleset-customization/trigger-async-sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RuleSetCustomizationSyncDtoToJSON(requestParameters['ruleSetCustomizationSyncDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleSetCustomizationSyncResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async triggerSync(requestParameters: TriggerSyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RuleSetCustomizationSyncResponseDto> {
        const response = await this.triggerSyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SearchContractRuleSetCustomizationsStateEnum = {
    Active: 'ACTIVE',
    Passive: 'PASSIVE',
    All: 'ALL'
} as const;
export type SearchContractRuleSetCustomizationsStateEnum = typeof SearchContractRuleSetCustomizationsStateEnum[keyof typeof SearchContractRuleSetCustomizationsStateEnum];
