/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeDunningLevelRequestDto,
  ContractDunningStateDto,
  ContractRuleSetCustomizationResponseDto,
  ContractStateFilter,
  DunningPageRequest,
  ErrorResponse,
  PaginatedContractStateOverview,
  ReceivableDunningStateDto,
  RuleSetDunningLevelDto,
  UpdateRuleSetIdDto,
  UpdateSkipExecutionDto,
} from '../models/index';
import {
    ChangeDunningLevelRequestDtoFromJSON,
    ChangeDunningLevelRequestDtoToJSON,
    ContractDunningStateDtoFromJSON,
    ContractDunningStateDtoToJSON,
    ContractRuleSetCustomizationResponseDtoFromJSON,
    ContractRuleSetCustomizationResponseDtoToJSON,
    ContractStateFilterFromJSON,
    ContractStateFilterToJSON,
    DunningPageRequestFromJSON,
    DunningPageRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PaginatedContractStateOverviewFromJSON,
    PaginatedContractStateOverviewToJSON,
    ReceivableDunningStateDtoFromJSON,
    ReceivableDunningStateDtoToJSON,
    RuleSetDunningLevelDtoFromJSON,
    RuleSetDunningLevelDtoToJSON,
    UpdateRuleSetIdDtoFromJSON,
    UpdateRuleSetIdDtoToJSON,
    UpdateSkipExecutionDtoFromJSON,
    UpdateSkipExecutionDtoToJSON,
} from '../models/index';

export interface DownloadContractStatesRequest {
    filterDto: ContractStateFilter;
}

export interface GetContractStateByContractIdRequest {
    contractId: number;
}

export interface GetContractStatesByFilterRequest {
    filterDto: ContractStateFilter;
    dunningPageRequest: DunningPageRequest;
}

export interface GetDunningCasesRequest {
    contractId: number;
}

export interface GetDunningLevels2Request {
    contractId: number;
}

export interface SetRuleSetIdRequest {
    contractId: number;
    updateRuleSetIdDto: UpdateRuleSetIdDto;
}

export interface SetSkipExecutionRequest {
    contractId: number;
    updateSkipExecutionDto: UpdateSkipExecutionDto;
}

export interface UpdateDunningLevel2Request {
    receivableId: number;
    changeDunningLevelRequestDto: ChangeDunningLevelRequestDto;
}

/**
 * 
 */
export class ContractStateApi extends runtime.BaseAPI {

    /**
     * Download a csv file of the contract with latest execution which match the given filter conditions. If nothing provided, all matching contracts will be provided 
     * Download contract state CSV file
     */
    async downloadContractStatesRaw(requestParameters: DownloadContractStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['filterDto'] == null) {
            throw new runtime.RequiredError(
                'filterDto',
                'Required parameter "filterDto" was null or undefined when calling downloadContractStates().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filterDto'] != null) {
            queryParameters['filterDto'] = requestParameters['filterDto'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contract-state/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download a csv file of the contract with latest execution which match the given filter conditions. If nothing provided, all matching contracts will be provided 
     * Download contract state CSV file
     */
    async downloadContractStates(requestParameters: DownloadContractStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadContractStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Obtaining the status of a specific contract state by its ID.
     * Get contract state
     */
    async getContractStateByContractIdRaw(requestParameters: GetContractStateByContractIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContractDunningStateDto>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getContractStateByContractId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contract-state/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractDunningStateDtoFromJSON(jsonValue));
    }

    /**
     * Obtaining the status of a specific contract state by its ID.
     * Get contract state
     */
    async getContractStateByContractId(requestParameters: GetContractStateByContractIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContractDunningStateDto> {
        const response = await this.getContractStateByContractIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of contract decorated with last execution information. The result would be filtered based on the given search criteria. 
     * Get contract states with last executionInfo
     */
    async getContractStatesByFilterRaw(requestParameters: GetContractStatesByFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedContractStateOverview>> {
        if (requestParameters['filterDto'] == null) {
            throw new runtime.RequiredError(
                'filterDto',
                'Required parameter "filterDto" was null or undefined when calling getContractStatesByFilter().'
            );
        }

        if (requestParameters['dunningPageRequest'] == null) {
            throw new runtime.RequiredError(
                'dunningPageRequest',
                'Required parameter "dunningPageRequest" was null or undefined when calling getContractStatesByFilter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filterDto'] != null) {
            queryParameters['filterDto'] = requestParameters['filterDto'];
        }

        if (requestParameters['dunningPageRequest'] != null) {
            queryParameters['dunningPageRequest'] = requestParameters['dunningPageRequest'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contract-state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedContractStateOverviewFromJSON(jsonValue));
    }

    /**
     * Get list of contract decorated with last execution information. The result would be filtered based on the given search criteria. 
     * Get contract states with last executionInfo
     */
    async getContractStatesByFilter(requestParameters: GetContractStatesByFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedContractStateOverview> {
        const response = await this.getContractStatesByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Provide a `contractId` as query parameter, and this endpoint will return all cases and its items related to this contract. If no cases are found it simply returns an empty array. 
     * Get receivable states
     */
    async getDunningCasesRaw(requestParameters: GetDunningCasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReceivableDunningStateDto>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDunningCases().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contract-state/{contractId}/receivables`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReceivableDunningStateDtoFromJSON));
    }

    /**
     * Provide a `contractId` as query parameter, and this endpoint will return all cases and its items related to this contract. If no cases are found it simply returns an empty array. 
     * Get receivable states
     */
    async getDunningCases(requestParameters: GetDunningCasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReceivableDunningStateDto>> {
        const response = await this.getDunningCasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible levels that can be assigned to a contract
     * Get contract levels
     */
    async getDunningLevels2Raw(requestParameters: GetDunningLevels2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RuleSetDunningLevelDto>>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling getDunningLevels2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/contract-state/{contractId}/assignable-levels`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleSetDunningLevelDtoFromJSON));
    }

    /**
     * Get all possible levels that can be assigned to a contract
     * Get contract levels
     */
    async getDunningLevels2(requestParameters: GetDunningLevels2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RuleSetDunningLevelDto>> {
        const response = await this.getDunningLevels2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Any contract can be assigned a custom rule set, which will then be executed for that contract, ignoring the default rule set. 
     * Assign ruleset to contract
     */
    async setRuleSetIdRaw(requestParameters: SetRuleSetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContractRuleSetCustomizationResponseDto>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setRuleSetId().'
            );
        }

        if (requestParameters['updateRuleSetIdDto'] == null) {
            throw new runtime.RequiredError(
                'updateRuleSetIdDto',
                'Required parameter "updateRuleSetIdDto" was null or undefined when calling setRuleSetId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contract-state/{contractId}/ruleset`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRuleSetIdDtoToJSON(requestParameters['updateRuleSetIdDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractRuleSetCustomizationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Any contract can be assigned a custom rule set, which will then be executed for that contract, ignoring the default rule set. 
     * Assign ruleset to contract
     */
    async setRuleSetId(requestParameters: SetRuleSetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContractRuleSetCustomizationResponseDto> {
        const response = await this.setRuleSetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Any contract can be flagged to skip execution, in which case it will be excluded from the execution process until the flag is removed. 
     * Set up a skip contract
     */
    async setSkipExecutionRaw(requestParameters: SetSkipExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContractDunningStateDto>> {
        if (requestParameters['contractId'] == null) {
            throw new runtime.RequiredError(
                'contractId',
                'Required parameter "contractId" was null or undefined when calling setSkipExecution().'
            );
        }

        if (requestParameters['updateSkipExecutionDto'] == null) {
            throw new runtime.RequiredError(
                'updateSkipExecutionDto',
                'Required parameter "updateSkipExecutionDto" was null or undefined when calling setSkipExecution().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contract-state/{contractId}/skip-execution`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters['contractId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSkipExecutionDtoToJSON(requestParameters['updateSkipExecutionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractDunningStateDtoFromJSON(jsonValue));
    }

    /**
     * Any contract can be flagged to skip execution, in which case it will be excluded from the execution process until the flag is removed. 
     * Set up a skip contract
     */
    async setSkipExecution(requestParameters: SetSkipExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContractDunningStateDto> {
        const response = await this.setSkipExecutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The customer can manually set a level to a receivable
     * Set dunning level
     */
    async updateDunningLevel2Raw(requestParameters: UpdateDunningLevel2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['receivableId'] == null) {
            throw new runtime.RequiredError(
                'receivableId',
                'Required parameter "receivableId" was null or undefined when calling updateDunningLevel2().'
            );
        }

        if (requestParameters['changeDunningLevelRequestDto'] == null) {
            throw new runtime.RequiredError(
                'changeDunningLevelRequestDto',
                'Required parameter "changeDunningLevelRequestDto" was null or undefined when calling updateDunningLevel2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contract-state/receivable/{receivableId}/level`.replace(`{${"receivableId"}}`, encodeURIComponent(String(requestParameters['receivableId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeDunningLevelRequestDtoToJSON(requestParameters['changeDunningLevelRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The customer can manually set a level to a receivable
     * Set dunning level
     */
    async updateDunningLevel2(requestParameters: UpdateDunningLevel2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDunningLevel2Raw(requestParameters, initOverrides);
    }

}
