/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkExecutionStatsDto
 */
export interface BulkExecutionStatsDto {
    /**
     * Number of outcomes that will be restarted
     * @type {number}
     * @memberof BulkExecutionStatsDto
     */
    executionOutcomesToRetry?: number;
}

/**
 * Check if a given object implements the BulkExecutionStatsDto interface.
 */
export function instanceOfBulkExecutionStatsDto(value: object): value is BulkExecutionStatsDto {
    return true;
}

export function BulkExecutionStatsDtoFromJSON(json: any): BulkExecutionStatsDto {
    return BulkExecutionStatsDtoFromJSONTyped(json, false);
}

export function BulkExecutionStatsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkExecutionStatsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'executionOutcomesToRetry': json['executionOutcomesToRetry'] == null ? undefined : json['executionOutcomesToRetry'],
    };
}

export function BulkExecutionStatsDtoToJSON(value?: BulkExecutionStatsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'executionOutcomesToRetry': value['executionOutcomesToRetry'],
    };
}

